import React, { useEffect, useState, useRef, } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setOpenLoader, setTitleLoader, } from '../../../../config/redux/actions';
import WorkerService from '../../../../services/worker';
import CustomInputSearch from '../../General/CustomInputSearch';
import CustomModal from "../../../components/General/CustomModal";
import InformationWorker from '../InformationWorker/InformationWorker';
import { titlesModal } from '../../../../message/titlesModal';
import { CODES } from '../../../../utils/codesHTTP';
import { titlesSpinner } from '../../../../message/titlesSpinner';
import { simpleAlerts } from '../../../../utils/alerts';
import { titlesAlerts } from '../../../../message/titlesAlerts';

const AddWorker = (props) => {
    const { open, setOpen, pageRequest, typeSelection = "multiple", valuePlantAux = null,
        valueTypeWorker, valueTypeInvolved,
        workersSelected, setWorkersSelected, } = props;


    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));
    const valuePlant = useSelector(state => state.valuePlant);
    const [dataWorkers, setDataWorkers] = useState(null);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(null)

    useEffect(() => {
        if (open && +valueTypeWorker === 1) {
            handleSearchWorker();
        }
    }, [open, valuePlant, valueTypeWorker, page]);

    useEffect(() => {
        setDataWorkers(null);
    }, [search])

    const handleClose = () => {
        setOpen(false);
        setSearch(null);
        setDataWorkers(null);
        setWorkersSelected(null);
    }

    const handleSearchWorker = () => {
        if (valuePlant > 0 || valuePlantAux > 0) {
            if (+valueTypeWorker === 1) {
                getWorkerPlant();
            } else {
                getWorkerOthers();
            }
        }
    }

    const getWorkerPlant = async () => {
        try {
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.search_workers);
            setDataWorkers(null);
            const params = {
                search: search,
                page: page,
                id_planta: valuePlant || valuePlantAux,
            }
            const result = await WorkerService.showByPlant(params);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result?.data?.data);
                setTotalPage(result?.data?.last_page);
            }
            return []
        } catch (error) {
            console.log(`Error en showCompanies: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const getWorkerOthers = async () => {
        try {
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.search_workers);
            setDataWorkers(null);
            const params = {
                numdoc: search,
            }
            const result = await WorkerService.showOthers(params);
            if (result.status === CODES.SUCCESS_200) {
                setDataWorkers(result?.data);
            } else {
                simpleAlerts({ message: result?.response?.data?.message || titlesAlerts.notfound_worker })
            }
            return []
        } catch (error) {
            console.log(`Error en showCompanies: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const timerRef = useRef(null);
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearchWorker();
        } else {
            // Reset the timer whenever a key is pressed
            clearTimeout(timerRef.current);

            // Set a new timer that will execute handleSearchWorker after 30 seconds
            timerRef.current = setTimeout(() => {
                handleSearchWorker();
            }, 10000); // 10000 ms = 10 seconds
        }

    };

    return (
        <CustomModal
            size={'sm'}
            open={open}
            handleClose={handleClose}
            // formSubmit={saveUser}
            title={titlesModal.listWorker}
            subTitle={titlesModal.listWorker_sub}
            bodyModal={
                <div className='px-1'>
                    <div className='row'>
                        <div className='col-12'>
                            <CustomInputSearch
                                placeholder="Buscar trabajador"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onClick={handleSearchWorker}
                                handleKeyPress={handleKeyPress}
                            />
                        </div>
                    </div>
                    <InformationWorker
                        typeSelection={typeSelection}
                        dataWorkers={dataWorkers}
                        page={page}
                        setPage={setPage}
                        totalPage={totalPage}
                        valueTypeWorker={valueTypeWorker}
                        valueTypeInvolved={valueTypeInvolved}
                        workersSelected={workersSelected}
                        setWorkersSelected={setWorkersSelected}
                    />
                    {/* {pageRequest === 'ACCIDENT' ?
                        <AccidentWorker
                            dataWorkers={dataWorkers}
                            page={page}
                            setPage={setPage}
                            totalPage={totalPage}
                            valueTypeWorker={valueTypeWorker}
                            valueTypeInvolved={valueTypeInvolved}
                            workersSelected={workersSelected}
                            setWorkersSelected={setWorkersSelected}
                        />
                        : pageRequest === 'UNSAFEBEHAVIOR' ?
                            <AccidentWorker
                                dataWorkers={dataWorkers}
                                page={page}
                                setPage={setPage}
                                totalPage={totalPage}
                                valueTypeWorker={valueTypeWorker}
                                workersSelected={workersSelected}
                                setWorkersSelected={setWorkersSelected}
                            />
                            : null} */}
                </div>
            }
        // buttonActions={[
        //     <button
        //         className='btn btn-sm btn-outline-primary ms-2'
        //         type='buttom'
        //         // onClick={addPlace}
        //         title='Buscar Trabajadores'
        //     >
        //         Guardar
        //     </button>
        // ]}
        />
    )
}

export default AddWorker