import React, { useState, useEffect, } from 'react'
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import "../../../assets/styles/login.scss"
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PasswordIcon from '@mui/icons-material/Password';
import AuthService from '../../../services/auth';
import { simpleAlerts } from "../../../utils/alerts";
import { clearBrowserCache } from '../../../utils/function';
import { CODES } from "../../../utils/codesHTTP";
import { Global } from '../../../config/general';
import { message } from 'antd';

const Login = ({ setUserInfo, }) => {
    const info = Global.info;
    const infoTime = Global.infoTime;

    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    useEffect(() => {
        handleOpenLoader(false);
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        handleOpenLoader(true);
        handleTitleLoader('Validando ...');
        try {
            const data = new FormData(event.currentTarget);
            const result = await AuthService.signIn(data);
            if (result?.status === CODES.SUCCESS_200) {
                const expirationDate = new Date(new Date().getTime() + result?.data?.expires_in * 1000);
                localStorage.setItem(infoTime, expirationDate);
                localStorage.setItem(info, JSON.stringify(result?.data));
                setUserInfo(result?.data);
                clearBrowserCache()
            } else {
                simpleAlerts({ message: result?.data?.message });
            }
            return [];
        } catch (error) {
            console.log(`Error en signIn: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    };

    return (
        <form onSubmit={handleSubmit} className='login-form'>
            <div className="login-wrap">
                <h2>RunaSST</h2>
                <div className="profile-image-container">
                    <img src={`${process.env.PUBLIC_URL}/icon.png`} alt="Profile" className="profile-image" />
                </div>
                <div className="form-login">
                    <input type="text" name="usuario" placeholder="Username" required />
                    <input type="password" name="password" placeholder="Password" required />
                    <button type="submit">Sign in</button>
                    {/* <button type="submit" className="btn btn-block">Login</button> */}
                    {/* <a href="#" onClick={toggleForm}>
                <p>{isRegister ? 'Have an account? Sign in' : "Don't have an account? Register"}</p>
            </a> */}
                </div>
            </div>
        </form>
    )
}

export default Login