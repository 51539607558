// appReducer.js
const initialState = {
    plants: null,
    areas: null,
    subAreas: null,
    places: null,
    headquarters: null,

    valuePlant: null,
    valueArea: null,
    valueSubArea: null,
    valuePlace: null,

    openLoader: false,
    titleLoader: null,
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_INFO':
            return { ...state, ...action.payload };
        case 'SET_VALUE_AREA':
            return { ...state, valueArea: action.payload };
        case 'SET_VALUE_SUBAREA':
            return { ...state, valueSubArea: action.payload };
        case 'SET_VALUE_PLACE':
            return { ...state, valuePlace: action.payload };
        case 'SET_OPEN_LOADER':
            return { ...state, openLoader: action.payload };
        case 'SET_TITLE_LOADER':
            return { ...state, titleLoader: action.payload };
        default:
            return state;
    }
};

export default appReducer;
