import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CODES } from "../../../../../utils/codesHTTP";
import { setOpenLoader, setTitleLoader } from '../../../../../config/redux/actions';
import DynamicTitle from '../../../../../utils/DynamicTitle';
import TypeEventService from '../../../../../services/typeEvent';
import GeneralService from "../../../../../services/general";
import AreaService from '../../../../../services/area';
import TypeEvent from './TypeEvent';
import TypeEventAssignment from './TypeEventAssignment';
import { titlesSpinner } from '../../../../../message/titlesSpinner';

const DefinitionsTypeEvent = () => {
  const dispatch = useDispatch();
  const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
  const handleTitleLoader = (value) => dispatch(setTitleLoader(value));
  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = (index) => setActiveTab(index);

  const [states, setStates] = useState(null);

  const [dataAreas, setDataAreas] = useState(null);
  const [typeEvent, setTypeEvent] = useState(null);
  const [typeEventCopy, setTypeEventCopy] = useState(null);

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    handleOpenLoader(true);
    handleTitleLoader(titlesSpinner.charge_init);
    Promise.all([
      getTypeEvent(),
      getAreas(),
      getStates(),
    ]).then(() => handleOpenLoader(false));
  }

  const getStates = async () => {
    try {
      setStates(null);
      const result = await GeneralService.showStates();
      if (result.status === CODES.SUCCESS_200) {
        setStates(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en Plant showAll: ${error}`);
    }
  }

  const getAreas = async () => {
    try {
      setDataAreas([]);
      const result = await AreaService.showAll();
      if (result.status === CODES.SUCCESS_200) {
        setDataAreas(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en AreaService showAll: ${error}`);
    }
  }

  const getTypeEvent = async () => {
    try {
      setTypeEventCopy([]);
      const result = await TypeEventService.showAll();
      if (result.status === CODES.SUCCESS_200) {
        setTypeEvent(result.data);
        setTypeEventCopy([...result.data]);
      }
      return []
    } catch (error) {
      console.log(`Error en AreaService showAll: ${error}`);
    }
  }

  return (
    <>
      <DynamicTitle title="Definición de Estructura" />
      <ul className="nav nav-underline justify-content-center">
        <li className="nav-item" onClick={() => handleChangeTab(0)}>
          <a className={`nav-link ${activeTab === 0 ? 'active' : ''}`}>Definiciones</a>
        </li>
        <li className="nav-item" onClick={() => handleChangeTab(1)}>
          <a className={`nav-link ${activeTab === 1 ? 'active' : ''}`}>Asignaciones</a>
        </li>
      </ul>
      <div className="content-container">
        <div className={`nav-content ${activeTab === 0 ? 'nav-slide-in' : ''}`}>
          <TypeEvent
            states={states}
            data={typeEvent}
            setData={setTypeEvent}
            getInfo={getTypeEvent}
          />
        </div>
        <div className={`nav-content ${activeTab === 1 ? 'nav-slide-in' : ''}`}>
          <TypeEventAssignment
            states={states}
            data={typeEventCopy}
            setData={setTypeEventCopy}
            // getInfo={getTypeEventByArea}
            dataAreas={dataAreas}
          />
        </div>
      </div>
    </>
  )
}

export default DefinitionsTypeEvent