import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../../config/redux/actions';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GeneralService from '../../../../../services/general';
import BodyBehavior from '../../../../components/Definitions/Concepts/Behavior/BodyBehavior'
import BodyTypeEvent from '../../../../components/Definitions/Concepts/TypeEvent/BodyTypeEvent';
import CustomSelect from '../../../../components/General/CustomSelect';
import CustomInput from '../../../../components/General/CustomInput';
import { CODES } from "../../../../../utils/codesHTTP";
import _ from 'lodash';

const newInfo = {
    desTipoEvento: null,
    estado_id: 1,
    edit: 1,
}

const TypeEvent = (props) => {
    const { states, data, setData, getInfo } = props;

    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    
    const addTypeEvent = () => {
        let updData = _.cloneDeep(data);
        updData?.push(newInfo);
        return setData(updData);
    }

    const handleSearch = (search) => {
        setSearch(search);
        const filtered = data.filter(item =>
            item.desTipoEvento.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredData(filtered);
    };

    return (
        <div>
            <h1 className='text-center title-page'>Definición Tipo Evento</h1>
            <div className='row justify-content-center'>
                <div className='col-md-5'>
                    <CustomInput
                        placeholder={'Buscar tipo evento'}
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                <div className='col-md-2 d-flex justify-content-evenly align-items-end'>
                    <button className='btn btn-sm btn-primary' type='button' onClick={addTypeEvent}><AddCircleIcon fontSize='small' /> Crear</button>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-7 col-sm-10'>
                    <BodyTypeEvent
                        states={states}
                        data={filteredData.length > 0 ? filteredData : data}
                        setData={setData}
                        id={"id_tipoEvento"}                       
                    />
                </div>
            </div>
        </div>
    )
}

export default TypeEvent