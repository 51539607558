import React, { useState, useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/redux/actions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BodyCategory from '../../../components/Definitions/Concepts/Category/BodyCategory'
import CustomSelect from '../../../components/General/CustomSelect';
import CustomInput from '../../../components/General/CustomInput';
import _ from 'lodash';
import { simpleAlerts } from '../../../../utils/alerts';
import { validationMessages } from '../../../../message/validations';

const Category = (props) => {
    const { states, data, setData, getInfo, dataBehaviors,valueBehavior, setValueBehavior } = props;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
  
    const handleSearch = (search) => {
        setSearch(search);
        const filtered = data.filter(item =>
            item.desCategoTipoCompor.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredData(filtered);
    };
 
    const newInfo = {
        desCategoTipoCompor: null,
        tipoComportamiento_id: valueBehavior,
        estado_id: 1,
        edit: 1,
    }
    
    const addCategoryByTypeBehavior = () => {
        if (valueBehavior <= 0) {
             simpleAlerts({ message: validationMessages.add_category_behavior });
            return; 
        }
        let updData = _.cloneDeep(data);
        updData?.push(newInfo);
        return setData(updData);
    }

    return (
        <div>
            <h1 className='text-center title-page'>Def. Categoria - Tipo Comp.</h1>
            <div className='row justify-content-center'>
                <div className='col-md-2'>
                    <CustomInput
                        title={'Buscar'}
                        placeholder={'Buscar categorias'}
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                <div className='col-md-3'>
                    <CustomSelect
                        title={'Comportamiento'}
                        placeholder={'Selecionar comportamiento'}
                        options={dataBehaviors}
                        value={valueBehavior || 0}
                        onChange={(value, event) => setValueBehavior(value)}
                    />
                </div>
                <div className='col-md-1 d-flex justify-content-evenly align-items-end'>
                    <button className='btn btn-sm btn-primary' onClick={addCategoryByTypeBehavior}><AddCircleIcon fontSize='small' /> Agregar</button>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-7 col-sm-10'>
                    <BodyCategory 
                         states={states}
                         getInfo={getInfo}
                         data={filteredData.length > 0 ? filteredData : data}
                         setData={setData}
                         id={"id_categoTipoCompor"}
                    
                    />
                </div>
            </div>
        </div>
    )
}

export default Category