import React, { useState, useEffect, useRef, } from 'react';

const CustomInputInfoSingleLine = (props) => {
    const { style, title, value, name, } = props;
    const titleRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        if (titleRef.current) {
            setIsTruncated(titleRef.current.scrollWidth > titleRef.current.clientWidth);
        }
    }, [title]);

    return (
        <div className="custom-input-group-single-line">
            {title &&
                <label
                    ref={titleRef}
                    className={`form-label title-legend ${isTruncated ? 'truncated' : ''}`}
                    title={title}
                >
                    {`${title}:`}
                </label>
            }
            <label
                className="form-control text"
                name={name || ''}
                style={{
                    ...style,
                    height: '1.9rem',
                    backgroundColor: "#D5DBDB",
                }}
            >
                {value || ''}
            </label>
        </div>
    );
};

export default CustomInputInfoSingleLine;