import React, { useState, useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../../config/redux/actions';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import TypeEventService from '../../../../../services/typeEvent';
import BodyTypeEventAssignment from '../../../../components/Definitions/Concepts/TypeEventAssignment/BodyTypeEventAssignment';
import CustomSelect from '../../../../components/General/CustomSelect';
import { CODES } from '../../../../../utils/codesHTTP';
import Swal from 'sweetalert2';
import { simpleAlerts } from '../../../../../utils/alerts';
import { validationMessages } from '../../../../../message/validations';
import { titlesSpinner } from '../../../../../message/titlesSpinner';
import { titlesAlerts } from '../../../../../message/titlesAlerts';

const TypeEventAssignment = (props) => {
    const { states, data, setData, getInfo, dataAreas } = props;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [dataSelected, setDataSelected] = useState(null);
    const [dataExists, setDataExists] = useState(null);
    const [valueArea, setValueArea] = useState(null);

    useEffect(() => {
        setDataExists(null)
        if (valueArea > 0) {
            getTypeEventByArea();
        }
    }, [valueArea])

    const getTypeEventByArea = async () => {
        try {
            setDataExists(null);
            setDataSelected(null);
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.typeEventByArea_list);
            const result = await TypeEventService.showTypeEventByArea(valueArea);
            if (result.status === CODES.SUCCESS_200) {
                setDataExists(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en Plant getAreasByPlanta: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const saveTypeEventByArea = async (id_estado) => {
        if (dataSelected?.find(e => +e.estado_id !== 1)) {
            simpleAlerts({ message: validationMessages.assign_behavior_state });
            return;
        }
        if (!id_estado || id_estado === 0) {
            simpleAlerts({ message: validationMessages.estado });
            return;
        }
        if (!valueArea || valueArea === 0) {
            simpleAlerts({ message: validationMessages.planta });
            return;
        }

        const resultSwal = await Swal.fire({
            title: `${+id_estado === 2 ? titlesAlerts.confirm_type_event_plant_inactive : titlesAlerts.confirm_type_event_plant}`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                const body = {
                    id_area: valueArea,
                    id_estado,
                    tipoEventos: dataSelected
                };
                handleOpenLoader(true);
                handleTitleLoader(titlesSpinner.behavior_save);
                const result = await TypeEventService.saveTypeEventByArea(body);
                if (result.status === CODES.SUCCESS_200) {
                    getTypeEventByArea();
                    simpleAlerts({ message: (result?.data?.message || ""), type: "success" })
                } else {
                    simpleAlerts({ message: (result?.response?.data?.message || ""), type: "error" })
                }
                return []
            } catch (error) {
                console.log(`Error en Comportamiento savePlantaComportamiento: ${error}`);
            } finally {
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    }

    return (
        <div>
            <h1 className='text-center title-page'>Asig. Tipo Evento a Area</h1>
            <div className='row justify-content-center'>
                <div className='col-md-2'>
                    <CustomSelect
                        title={'Area'}
                        placeholder={'Selecionar área'}
                        options={dataAreas}
                        value={valueArea || 0}
                        onChange={(value, event) => setValueArea(value)}
                    />
                </div>
                <div className='col-md-2 d-flex justify-content-evenly align-items-end'>
                    <button className='btn btn-sm btn-primary' onClick={() => saveTypeEventByArea(1)}><AddHomeWorkIcon fontSize='small' />  Agregar</button>
                    <button className='btn btn-sm btn-danger' onClick={() => saveTypeEventByArea(2)}><DeleteSweepIcon fontSize='small' />  Retirar</button>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-7 col-sm-10'>
                    <BodyTypeEventAssignment
                        states={states}
                        getInfo={getInfo}
                        data={data}
                        setData={setData}
                        dataExists={dataExists}
                        dataSelected={dataSelected}
                        setDataSelected={setDataSelected}
                        id={"id_tipoEvento"}
                    />
                </div>
            </div>
        </div>
    )
}

export default TypeEventAssignment