import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;
const prefix = 'plant';
const url = window.location.pathname;
const commonParams = { url: url };

class PlantService {
    async showAll(queryParameters = null) {
        const ruta = `${API_URL}/${prefix}`;
        const headers = await headerRequest();
        const params = { ...commonParams, ...queryParameters };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async saveOrUpdate(body) {
        const ruta = `${API_URL}/${prefix}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.post(ruta, body, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showPlantHeadquarters(id_planta) {
        const ruta = `${API_URL}/${prefix}/headquarters/${id_planta}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async saveOrUpdatePlantHeadquarters(body) {
        const ruta = `${API_URL}/${prefix}/headquarters`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.post(ruta, body, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }
}

export default new PlantService();