import React from 'react'

const CustomNavPagination = ({ id = 'x', title = '', value = null, setValue }) => {
    return (
        <>
            <input
                type="radio"
                name="nav-pagination"
                id={`tab${id}`}
                className={`tab-content-${id}`}
                checked={+value === +id}
                onChange={(e) => setValue(+id)}
            />
            <label htmlFor={`tab${id}`} className={`nav-pagination-bg-${id}`}>{title}</label>
        </>
    )
}

export default CustomNavPagination