import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from "../../../../../config/redux/actions"
import GeneralService from '../../../../../services/general';
import PlantService from '../../../../../services/plant';
import CustomModal from '../../../../components/General/CustomModal';
import InformationPlant from '../../../../components/Definitions/Structure/Plant/AddPlant/InformationPlant';
import { simpleAlerts } from '../../../../../utils/alerts';
import { CODES } from '../../../../../utils/codesHTTP';
import Swal from 'sweetalert2';
import { validationMessages } from '../../../../../message/validations';
import { titlesSpinner } from '../../../../../message/titlesSpinner';
import { titlesAlerts } from '../../../../../message/titlesAlerts';

const AddModalStructure = (props) => {
    const { open, setOpen, companies, states, information, setInformation} = props;

    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [clients, setClients] = useState(null);
    const [units, setUnits] = useState(null);
    const [data, setData] = useState(null);
    const [dataExists, setDataExists] = useState(null);
    const [dataSelected, setDataSelected] = useState(null);
    const [activeTab, setActiveTab] = useState(0);

    const [valueCompany, setValueCompany] = useState(null);
    const [valueClient, setValueClient] = useState(null);
    const [valueUnit, setValueUnit] = useState(null);

    useEffect(() => {
        setDataExists(null);
        setDataSelected(null);
        if (information?.id_planta > 0)
            showPlantHeadquarters();
    }, [information])

    const showPlantHeadquarters = async () => {
        try {
            setDataExists(null);
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.plantSedes_list);
            const result = await PlantService.showPlantHeadquarters(information?.id_planta);
            if (result.status === CODES.SUCCESS_200) {
                setDataExists(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en showPlantHeadquarters: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    useEffect(() => {
        setClients(null);
        setUnits(null);
        setData(null);
        //setDataSelected(null);

        setValueClient(null);
        setValueUnit(null);
        if (valueCompany > 0)
            showClients();
    }, [valueCompany])

    useEffect(() => {
        setUnits(null);
        setData(null);
        //setDataSelected(null);

        setValueUnit(null);
        if (valueClient > 0)
            showUnits();
    }, [valueClient])

    useEffect(() => {
        setData(null);
        //setDataSelected(null);
        if (valueUnit > 0)
            showHeadquarters();
    }, [valueUnit])

    const showClients = async () => {
        try {
            setClients(null);
            handleOpenLoader(true);
            const result = await GeneralService.showClients(valueCompany);
            if (result.status === CODES.SUCCESS_200) {
                setClients(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en showClients: ${error}`);
        } finally {
            handleOpenLoader(false);
        }
    }

    const showUnits = async () => {
        try {
            setUnits(null);
            handleOpenLoader(true);
            const result = await GeneralService.showUnits(valueClient);
            if (result.status === CODES.SUCCESS_200) {
                setUnits(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en showUnits: ${error}`);
        } finally {
            handleOpenLoader(false);
        }
    }

    const showHeadquarters = async () => {
        try {
            setData(null);
            handleOpenLoader(true);
            const result = await GeneralService.showHeadquarters(valueUnit);
            if (result.status === CODES.SUCCESS_200) {
                setData(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en setHeadquarters: ${error}`);
        } finally {
            handleOpenLoader(false);
        }
    }
    const closeModal = () => {
        setClients(null);
        setUnits(null);
        setData(null);
        setDataSelected(null);

        setData(null);
        setDataExists(null);
        setDataSelected(null);
        setActiveTab(0);

        setValueCompany(null);
        setValueClient(null);
        setValueUnit(null);

        setInformation(null);

        setOpen(false);
    }

    const saveOrUpdatePlantHeadquarters = async (id_estado) => {
        const body = {
            ...information,
            id_estado,
            sedes: dataSelected,
        };

        if (!dataSelected || dataSelected?.length === 0) {
            simpleAlerts({ message: validationMessages.sedes });
            return;
        }
        if (!id_estado || id_estado === 0) {
            simpleAlerts({ message: validationMessages.estado });
            return;
        }

        const resultSwal = await Swal.fire({
            title: +id_estado !== 1 ? titlesAlerts.confirm_plant_headquarters_inactive : titlesAlerts.confirm_plant_headquarters,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                handleOpenLoader(true);
                handleTitleLoader(id_estado !== 1 ? titlesSpinner.plantSedes_remove : titlesSpinner.plantSedes_add);
                const result = await PlantService.saveOrUpdatePlantHeadquarters(body);
                if (result.status === CODES.SUCCESS_200) {
                    simpleAlerts({ message: result?.data?.message, type: "success" });
                    closeModal();
                } else {
                    simpleAlerts({ message: result?.response?.data?.message || "Error", type: "error" });
                }
                return []
            } catch (error) {
                console.log(`Error en saveOrUpdatePlantHeadquarters: ${error}`);
            } finally {
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    }

    return (
        <CustomModal
            size={'sm'}
            open={open}
            handleClose={closeModal}
            title={'Relación Planta - Sedes (ASISPROD)'}
            subTitle={'Asociar/Retirar Sedes (ASISPROD)'}
            bodyModal={
                <InformationPlant
                    companies={companies}
                    clients={clients}
                    units={units}
                    states={states}
                    valueCompany={valueCompany}
                    setValueCompany={setValueCompany}
                    valueClient={valueClient}
                    setValueClient={setValueClient}
                    valueUnit={valueUnit}
                    setValueUnit={setValueUnit}
                    information={information}
                    data={data}
                    dataExists={dataExists}
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            }
            buttonActions={
                (() => {
                    switch (activeTab) {
                        case 0:
                            return (
                                [
                                    <button
                                        key={'btn-save'}
                                        type={'button'}
                                        className='btn btn-sm btn-dark'
                                        onClick={() => saveOrUpdatePlantHeadquarters(2)}
                                    >
                                        Retirar Sedes
                                    </button>
                                ]
                            );
                        case 1:
                            return (
                                [
                                    <button
                                        key={'btn-save'}
                                        type={'button'}
                                        className='btn btn-sm btn-success'
                                        onClick={() => saveOrUpdatePlantHeadquarters(1)}
                                    >
                                        Agregar Sedes
                                    </button>
                                ]
                            );
                        default:
                            return null;
                    }
                })()
            }
        />
    )
}

export default AddModalStructure