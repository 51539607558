import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;

class AuthService {
    signIn(body) {
        const ruta = `${API_URL}/login`;
        return axios.post(ruta, body)
            .catch((error) => {
                return error.response;
            });
    }

    async me() {
        const ruta = `${API_URL}/me`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    signOut(body) {
        const ruta = `${API_URL}/logout`;
        return axios.post(ruta, body)
            .catch((error) => {
                return error.response;
            });
    }
}

export default new AuthService();