import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CODES } from "../../../../utils/codesHTTP";
import { setOpenLoader, } from '../../../../config/redux/actions';
import Behavior from './Behavior';
import Category from './Category';
import BehaviorAssignment from './BehaviorAssignment';
import DynamicTitle from "../../../../utils/DynamicTitle";
import CategoryTypeBehaviorService from '../../../../services/categoryTypeBehavior';
import GeneralService from "../../../../services/general";
import PlantService from '../../../../services/plant';
import TypeBehaviorService from '../../../../services/typeBehavior';
import { titlesSpinner } from '../../../../message/titlesSpinner';
import { setTitleLoader } from '../../../../config/redux/actions';

const DefinitionsConcepts = () => {
  const dispatch = useDispatch();
  const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
  const handleTitleLoader = (value) => dispatch(setTitleLoader(value));
  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = (index) => setActiveTab(index);

  const [dataPlants, setDataPlants] = useState(null);
  const [dataBehaviors, setDataBehaviors] = useState(null);
  const [dataBehaviorsCategory, setDataBehaviorsCategory] = useState(null);

  const [dataBehaviorsCopy, setDataBehaviorsCopy] = useState(null);
  const [states, setStates] = useState(null);

  const [valueBehavior, setValueBehavior] = useState(null);

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    handleOpenLoader(true);
    Promise.all([
      getBehavior(),
      getStates(),
      getCategoryByTypeBehavior(),
      getPlants()
    ]).then(() => handleOpenLoader(false));
  }

  useEffect(() => {
    if (valueBehavior !== null && valueBehavior > 0) {
      getCategoryByTypeBehavior();
    }
  }, [valueBehavior])

  const getStates = async () => {
    try {
      setStates(null);
      const result = await GeneralService.showStates();
      if (result.status === CODES.SUCCESS_200) {
        setStates(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en Plant showAll: ${error}`);
    }
  }

  const getPlants = async () => {
    try {
      setDataPlants(null);
      const result = await PlantService.showAll();
      if (result.status === CODES.SUCCESS_200) {
        setDataPlants(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en Plant showAll: ${error}`);
    }
  }

  const getBehavior = async () => {
    try {
      setDataBehaviors([]);
      const result = await TypeBehaviorService.showAll();
      if (result.status === CODES.SUCCESS_200) {
        setDataBehaviors(result.data);
        setDataBehaviorsCopy([...result.data]);
      }
      return []
    } catch (error) {
      console.log(`Error en Comportamiento showAll: ${error}`);
    }
  }

  const getCategoryByTypeBehavior = async () => {
    try {
      if (valueBehavior === null || valueBehavior <= 0) {
        return;
      }

      setDataBehaviorsCategory(null);
      handleOpenLoader(true);
      handleTitleLoader(titlesSpinner.categoyBehavior_list);
      const result = await CategoryTypeBehaviorService.showCategoryByTypeBehavior(valueBehavior);
      if (result.status === CODES.SUCCESS_200) {
        setDataBehaviorsCategory(result.data);
      }
      return [];
    } catch (error) {
      console.log(`Error en Plant getAreasByPlanta: ${error}`);
    } finally {
      handleOpenLoader(false);
      handleTitleLoader(null);
    }
  };

  return (
    <>
      <DynamicTitle title="Definición de Estructura" />
      <ul className="nav nav-underline justify-content-center">
        <li className="nav-item" onClick={() => handleChangeTab(0)}>
          <a className={`nav-link ${activeTab === 0 ? 'active' : ''}`}>Comportamiento</a>
        </li>
        <li className="nav-item" onClick={() => handleChangeTab(1)}>
          <a className={`nav-link ${activeTab === 1 ? 'active' : ''}`}>Categoria</a>
        </li>
        <li className="nav-item" onClick={() => handleChangeTab(2)}>
          <a className={`nav-link ${activeTab === 2 ? 'active' : ''}`}>Asignaciones</a>
        </li>
      </ul>
      <div className="content-container">
        <div className={`nav-content ${activeTab === 0 ? 'nav-slide-in' : ''}`}>
          <Behavior
            states={states}
            data={dataBehaviors}
            setData={setDataBehaviors}
            getInfo={getBehavior}
          />
        </div>
        <div className={`nav-content ${activeTab === 1 ? 'nav-slide-in' : ''}`}>
          <Category
            states={states}
            data={dataBehaviorsCategory}
            setData={setDataBehaviorsCategory}
            getInfo={getCategoryByTypeBehavior}
            dataBehaviors={dataBehaviors}
            valueBehavior={valueBehavior}
            setValueBehavior={setValueBehavior}
          />
        </div>
        <div className={`nav-content ${activeTab === 2 ? 'nav-slide-in' : ''}`}>
          <BehaviorAssignment
            states={states}
            data={dataBehaviorsCopy}
            setData={setDataBehaviorsCopy}
            getInfo={getBehavior}
            dataPlants={dataPlants}
          />
        </div>
      </div>
    </>
  )
}

export default DefinitionsConcepts