import React, { useState, useEffect, useRef, } from 'react';

const CustomInputSingleLine = (props) => {
    const { title, readonly, placeholder, type, value, required, name, onChange, style, handleKeyPress, propsInput } = props;
    const titleRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        if (titleRef.current) {
            setIsTruncated(titleRef.current.scrollWidth > titleRef.current.clientWidth);
        }
    }, [title]);

    return (
        <div className="custom-input-group-single-line">
            {title &&
                <label
                    ref={titleRef}
                    className={`form-label title-legend ${isTruncated ? 'truncated' : ''}`}
                    title={title}
                >
                    {`${title}:`}
                </label>
            }
            <input
                className="form-control text"
                type={type || 'text'}
                value={value || ''}
                onChange={onChange}
                name={name || ''}
                placeholder={placeholder || ''}
                required={required || false}
                readOnly={readonly || false}
                {...propsInput}
                style={{ ...style }}
                onKeyPress={handleKeyPress}
            />
        </div>
    );
};

export default CustomInputSingleLine;