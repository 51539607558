import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GeneralService from '../../../services/general';
import UnsafeBehaviorService from '../../../services/unsafeBehavior';
import TypeBehaviorService from '../../../services/typeBehavior';
import CustomSelect from "../../components/General/CustomSelect";
import IncidentLocationFields from '../../components/General/IncidentLocationFields';
import CustomTextArea from '../../components/General/CustomTextArea';
import BodyWorkersAdd from '../../components/Workers/BodyWorkersAdd';
import AddWorker from '../../components/Workers/AddWorker/AddWorker';
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../message/validations';
import { getDateOL } from '../../../utils/function';
import { titlesSpinner } from '../../../message/titlesSpinner';
import { titlesAlerts } from '../../../message/titlesAlerts';
import { errorsMessages } from '../../../message/errors';
import { CODES } from '../../../utils/codesHTTP';
import _ from 'lodash';
import Swal from 'sweetalert2';
import DynamicTitle from '../../../utils/DynamicTitle';

const newInfo = {
    fecha: getDateOL("", "large"),
    planta_id: null,
    area_id: null,
    subArea_id: null,
    lugar_id: null,
    subAreaLugar_id: null,
    categoTipoCompor_id: null,
    turno_id: null,
    desSuceso: null,
    desAccion: null,
    trabajadores: [
        // {
        //     "id_comporInseguD": 4,
        //     "comporInseguC_id": "4",
        //     "persona_id": "7227",
        //     "tipoTrabajador_id": "1"
        // }
    ],
    imagenes: [],
}

const UnsafeBehavior = () => {
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [info, setInfo] = useState(newInfo);
    

    const valuePlant = useSelector(state => state.valuePlant);
    const places = useSelector(state => state.places);

    const [workersSelected, setWorkersSelected] = useState(info?.id_comporInseguC ? info?.trabajadores : null);
    const [valueTypeWorker, setValueTypeWorker] = useState(null);
    const [valueTypeBehavior, setValueTypeBehavior] = useState(null);
    const [dataTypeWorker, setDataTypeWorker] = useState(null);
    const [dataShift, setDataShift] = useState(null);

    const [turno, setTurno] = useState(null);

    const [dataTypeBehavior, setDataTypeBehavior] = useState(null);
    const [dataCategory, setDataCategory] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        handleOpenLoader(true);
        Promise.all([
            showTypeWorker(),showTurnos()
        ]).then(() => handleOpenLoader(false));
    }

    const showTypeWorker = async () => {
        try {
            setDataTypeWorker(null);
            const result = await GeneralService.showTypeWorker();
            setDataTypeWorker(result.data);
            return [];
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    }

    useEffect(() => {
        if (valuePlant > 0) {
            showShiftByPlant();
            showTypeBehaviorByPlant();
        }
    }, [valuePlant]);

    const showShiftByPlant = async () => {
        try {
            setDataShift(null);
            const result = await GeneralService.showShiftByPlant(valuePlant);
            setDataShift(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showShiftByPlant: ${error}`);
        }
    }

    const showTypeBehaviorByPlant = async () => {
        try {
            setDataTypeBehavior(null);
            const result = await TypeBehaviorService.showTypeBehaviorByPlant(valuePlant);
            setDataTypeBehavior(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showShiftByPlant: ${error}`);
        }
    }

    useEffect(() => {
        setDataCategory(null);
        if (valueTypeBehavior > 0)
            showCategoryByTypeBehavior();
    }, [valueTypeBehavior]);

    const showCategoryByTypeBehavior = async () => {
        try {
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.categoyBehavior_list)
            setDataCategory(null);
            const result = await TypeBehaviorService.showCategoryByTypeBehavior(valueTypeBehavior);
            setDataCategory(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showShiftByPlant: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const allowedFileTypes = ['.jpg', '.jpeg', '.png'];
    const handleFilesChange = (e) => {
        const files = e.target.files;

        if (files.length > 5) {
            simpleAlerts({ message: errorsMessages.max_files_images });
            return; // Detiene la función si hay más de 5 archivos
        }

        let updData = _.cloneDeep(info);

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileExtension = file?.name?.split('.').pop().toLowerCase();
            if (!allowedFileTypes.includes(`.${fileExtension}`)) {
                simpleAlerts({ message: errorsMessages.type_image })
            } else {
                let small;
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        small = { file: file, img: event.target.result, name: file?.name };
                        updData["imagenes"].push({ file: file, small: small });
                        setInfo(updData);
                    };
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    const handleRemoveFile = (idx) => {
        let updData = _.cloneDeep(info);
        updData.imagenes = updData.imagenes?.filter((e, i) => +i !== idx);
        return setInfo(updData);
    };

    const viewFile = (info) => {
        const fileURL = URL.createObjectURL(info?.small?.file);
        if (info?.small?.file) {
            window.open(fileURL, '_blank');
        }
    };

    const handleOpenModal = () => {
        if (!valuePlant || valuePlant === 0) {
            simpleAlerts({ message: validationMessages.planta })
            return;
        }
        if (!valueTypeWorker || valueTypeWorker < 0) {
            simpleAlerts({ message: validationMessages.tipoTrabajador })
            return;
        }
        setOpen(true);
    }

    const resetInfo = () => {
        setInfo(newInfo);
        setWorkersSelected(null);
        setValueTypeWorker(null);
        setValueTypeBehavior(null);
    }

    const saveUnsafeBehavior = async (e) => {
        e.preventDefault();

        let updData = _.cloneDeep(info);
        if (!updData.fecha || updData.fecha === "") {
            simpleAlerts({ message: validationMessages.date })
            return;
        }
        if (!updData.lugar_id || updData.lugar_id === 0) {
            simpleAlerts({ message: validationMessages.lugar })
            return;
        }
        if (!updData.categoTipoCompor_id || updData.categoTipoCompor_id === 0) {
            simpleAlerts({ message: validationMessages.categoria })
            return;
        }
        if (!updData.turno_id || updData.turno_id === 0) {
            simpleAlerts({ message: validationMessages.turno })
            return;
        }
        if (!updData.desSuceso || updData.desSuceso === "") {
            simpleAlerts({ message: validationMessages.desSuceso })
            return;
        }
        if (!updData.desAccion || updData.desAccion === "") {
            simpleAlerts({ message: validationMessages.desAccion })
            return;
        }
        if (!updData.trabajadores || updData.trabajadores?.length === 0) {
            simpleAlerts({ message: validationMessages.trabajadores })
            return;
        }

        updData.planta_id = valuePlant;
        updData.subAreaLugar_id = places?.find(e =>
        (+e.planta_id === +updData.planta_id &&
            +e.area_id === +updData.area_id &&
            +e.subArea_id === +updData.subArea_id &&
            +e.id_lugar === +updData.lugar_id)
        )?.id_subAreaLugar

        const resultSwal = await Swal.fire({
            title: titlesAlerts.confirm_unsafe_behavior,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                handleOpenLoader(true);
                handleTitleLoader(titlesSpinner.comportamientoInseguro_save);

                const result = await UnsafeBehaviorService.saveUnsafeBehavior(updData);
                if (result.status === CODES.CREATE_201) {
                    resetInfo();
                    simpleAlerts({ message: (result?.data?.message || ""), type: "success" })
                } else {
                    console.log(result?.response);
                    simpleAlerts({ message: errorsMessages.unsafe_behavior, type: "error" })
                }
                return []
            } catch (error) {
                console.log(`Error en saveUnsafeBehavior: ${error}`);
            } finally {
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    }

    const showTurnos = async () => {
        try {
            setDataTypeWorker(null);
            const result = await GeneralService.showTurnos();
            setTurno(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showTurnos: ${error}`);
        }
    }

    useEffect(() => {
        if (workersSelected && workersSelected?.length > 0) {
            setInfo({ ...info, trabajadores: workersSelected });
        }
    }, [workersSelected])

    const setDataWorkers = (newWorkers) => {
        setInfo(prev => ({ ...prev, trabajadores: newWorkers }));
    };

    return (
        <form className="px-3"
            onSubmit={saveUnsafeBehavior}
        >
            <DynamicTitle title="Comportamiento inseguro" />
            <AddWorker
                open={open}
                setOpen={setOpen}
                pageRequest={"UNSAFEBEHAVIOR"}
                valueTypeWorker={valueTypeWorker}
                workersSelected={workersSelected}
                setWorkersSelected={setWorkersSelected}
            />
            <h1 className='text-center title-page'>Comportamiento Inseguro</h1>
            <section className='form-register'>
                <IncidentLocationFields
                    info={info}
                    setInfo={setInfo}
                />
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <CustomSelect
                            title={'Turno'}
                            placeholder={'Selecionar Turno'}
                           // options={dataShift}
                           options={turno} 
                           value={info.turno_id || 0}
                            onChange={(value, event) => setInfo({ ...info, turno_id: value })}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <CustomSelect
                            title={'Tipo de Comportamiento'}
                            placeholder={'Selecionar Tipo'}
                            options={dataTypeBehavior}
                            value={valueTypeBehavior || 0}
                            onChange={(value, event) => setValueTypeBehavior(value)}
                        />
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <CustomSelect
                            title={'Categoria de Comportamiento'}
                            placeholder={'Selecionar Categoria'}
                            options={dataCategory}
                            value={info.categoTipoCompor_id || 0}
                            onChange={(value, event) => setInfo({ ...info, categoTipoCompor_id: value })}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8 d-flex justify-content-between align-items-end'>
                        <CustomSelect
                            title={'Tipo de Trabajador'}
                            placeholder={'Selecionar Tipo'}
                            options={dataTypeWorker}
                            value={valueTypeWorker || 0}
                            onChange={(value, event) => setValueTypeWorker(value)}
                        />
                        <button
                            className='btn btn-sm btn-outline-primary ms-2'
                            type='button'
                            onClick={handleOpenModal}
                            title='Buscar Trabajadores'
                        >
                            <SearchIcon fontSize='small' />
                        </button>
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <div className='text-center'>
                            <input
                                id={`fileInput-form-register`}
                                type="file"
                                accept={allowedFileTypes}
                                onChange={handleFilesChange}
                                style={{ display: 'none' }}
                                multiple
                            />
                            <button
                                className='btn btn-sm btn-outline-primary ms-2'
                                type='button'
                                onClick={() => document.getElementById(`fileInput-form-register`).click()}
                                title='Subir Imagen'
                            >
                                <CloudUploadIcon color="inherit" fontSize='small' />
                            </button>
                        </div>
                        {info?.imagenes?.length > 0 &&
                            <div className='table-container-wrapper mt-2'>
                                <div className='table-container'>
                                    {info?.imagenes?.map((row, idxRow) => {
                                        return (
                                            <div key={`row-image-${idxRow}`} className='row-worker-container'>
                                                <div className='col-worker-container' style={{ width: "93%" }}>
                                                    <img
                                                        src={row.small.img}
                                                        alt="Debe seleccionar una imagen correcta"
                                                        style={{ width: '30px', cursor: 'pointer' }}
                                                        className='ms-3'
                                                        onClick={() => viewFile(row)}
                                                    />
                                                    <label className='ms-2'>{row.small.name}</label>
                                                </div>
                                                <div className='col-worker-container'>
                                                    <button
                                                        className='btn btn-sm btn-outline-danger ms-2 p-0 border-0'
                                                        type='button'
                                                        onClick={() => handleRemoveFile(idxRow)}
                                                        title='Quitar imagen'
                                                    >
                                                        <CancelIcon fontSize='small' />
                                                    </button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <BodyWorkersAdd
                            data={info.trabajadores}
                            setData={setDataWorkers}
                        />
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <CustomTextArea
                            title="Descripción"
                            placeholder="ingrese una descripción 500 max."
                            propsInput={{ maxLength: 500, }}
                            name="desSuceso"
                            value={info?.desSuceso || ""}
                            onChange={(e) => setInfo({ ...info, desSuceso: e.target.value })}
                            required={true}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <CustomTextArea
                            title="Acciones Realizadas"
                            placeholder="ingrese una descripción 500 max."
                            propsInput={{ maxLength: 500, }}
                            value={info?.desAccion || ""}
                            onChange={(e) => setInfo({ ...info, desAccion: e.target.value })}
                            required={true}
                        />
                    </div>
                </div>
                <div className='row justify-content-center gap-2'>
                    <div className='col-md-4 col-sm-8'>
                        <button
                            className='btn btn-block btn-danger w-100'
                            // onClick={addPlace}
                            title='Cancelar'
                        >
                            Cancelar
                        </button>
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <button
                            className='btn btn-block btn-primary w-100'
                            // onClick={addPlace}
                            title='Guardar'
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </section>
        </form>
    )
}

export default UnsafeBehavior