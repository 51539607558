import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;
const prefix = 'general';
const url = window.location.pathname;
const commonParams = { url: url };

class GeneralService {
    async showStates() {
        const ruta = `${API_URL}/${prefix}/states`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showTypeInvolved() {
        const ruta = `${API_URL}/${prefix}/type-involved`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showTypeWorker() {
        const ruta = `${API_URL}/${prefix}/type-worker`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showCompanies() {
        const ruta = `${API_URL}/${prefix}/companies`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showClients(id_empresa) {
        const ruta = `${API_URL}/${prefix}/clients/${id_empresa}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showUnits(id_cliente) {
        const ruta = `${API_URL}/${prefix}/units/${id_cliente}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showHeadquarters(id_unidad) {
        const ruta = `${API_URL}/${prefix}/headquarters/${id_unidad}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showGroupingHeadquarters() {
        const ruta = `${API_URL}/${prefix}/headquarter`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showShiftByPlant(id_planta) {
        const ruta = `${API_URL}/${prefix}/shift/plant/${id_planta}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showProfiles() {
        const ruta = `${API_URL}/${prefix}/profiles`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showTurnos() {
        const ruta = `${API_URL}/${prefix}/turnos`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async showMenu(queryParameters = null) {
        const ruta = `${API_URL}/${prefix}/menu`;
        const headers = await headerRequest();
        const params = { ...commonParams, ...queryParameters };
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async showTypeResponse() {
        const ruta = `${API_URL}/${prefix}/type-response`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async showSuggestionType() {
        const ruta = `${API_URL}/${prefix}/suggestion-type`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }

    async showStateActionPlan() {
        const ruta = `${API_URL}/${prefix}/state-action-plan`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }
    
    async showCalculationFormat() {
        const ruta = `${API_URL}/${prefix}/calculation-format`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }
}

export default new GeneralService();