import React from 'react';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ConstructionIcon from '@mui/icons-material/Construction';

const CustomTitleTable = ({ title = '', sort = true }) => {
    if (title.toUpperCase() === 'ACTIONS') {
        return <ConstructionIcon className='icon-header-col' />;
    } else {
        return (
            <div className='group-icon-header'>
                {sort ? <FormatListNumberedIcon className='icon-header-col' /> : ''}
                {title}
            </div>
        );
    }
}

export default CustomTitleTable;
