import React, { useState, useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/redux/actions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import BodyBehaviorAssignment from '../../../components/Definitions/Concepts/BehaviorAssignment/BodyBehaviorAssignment';
import CustomSelect from '../../../components/General/CustomSelect';
import CustomInput from '../../../components/General/CustomInput';
import { CODES } from '../../../../utils/codesHTTP';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { simpleAlerts } from '../../../../utils/alerts';
import { validationMessages } from '../../../../message/validations';
import { titlesSpinner } from '../../../../message/titlesSpinner';
import { titlesAlerts } from '../../../../message/titlesAlerts';
import TypeBehaviorService from '../../../../services/typeBehavior';

const BehaviorAssignment = (props) => {
    const { states, data, setData, getInfo, dataPlants } = props;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [dataSelected, setDataSelected] = useState(null);
    const [dataExists, setDataExists] = useState(null);
    const [valuePlant, setValuePlant] = useState(null);

    
    //const [dataBehaviors, setDataBehaviors] = useState(null);
    // const [valueBehavior, setValueBehavior] = useState(null);
    /* useEffect(() => {
         setDataBehaviors(null);
         setValueBehavior(null);
         if (valuePlant > 0) {
             getBehaviorsByPlanta();
         }
     }, [valuePlant])
 */
    useEffect(() => {
        setDataExists(null)
        if (valuePlant > 0) {
            getComportamientoPlanta();
        }
    }, [valuePlant])


    /* const getBehaviorsByPlanta = async () => {
         try {
             setDataExists(null);
             setDataSelected(null);
             handleOpenLoader(true);
             handleTitleLoader(titlesSpinner.behavior_plant_list);
             const result = await TypeBehaviorService.showTypeBehaviorByPlant(valuePlant);
             if (result.status === CODES.SUCCESS_200) {
                 setDataExists(result.data);
             }
             return []
         } catch (error) {
             console.log(`Error en Area getBehaviorsByPlanta: ${error}`);
         } finally {
             handleOpenLoader(false);
             handleTitleLoader(null);
         }
     }
 */
    const savePlantaComportamiento = async (id_estado) => {
        if (dataSelected?.find(e => +e.estado_id !== 1)) {
            simpleAlerts({ message: validationMessages.assign_behavior_state });
            return;
        }
        if (!id_estado || id_estado === 0) {
            simpleAlerts({ message: validationMessages.estado });
            return;
        }
        if (!valuePlant || valuePlant === 0) {
            simpleAlerts({ message: validationMessages.planta });
            return;
        }

        const resultSwal = await Swal.fire({
            title: `${+id_estado === 2 ? titlesAlerts.confirm_behavior_plant_inactive : titlesAlerts.confirm_behavior_plant}`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                const body = {
                    id_planta: valuePlant,
                    id_estado,
                    comportamientos: dataSelected
                };
                handleOpenLoader(true);
                handleTitleLoader(titlesSpinner.behavior_save);
                const result = await TypeBehaviorService.saveOrUpdatePlantBehavior(body);
                if (result.status === CODES.SUCCESS_200) {
                    getComportamientoPlanta();
                    simpleAlerts({ message: (result?.data?.message || ""), type: "success" })
                } else {
                    simpleAlerts({ message: (result?.response?.data?.message || ""), type: "error" })
                }
                return []
            } catch (error) {
                console.log(`Error en Comportamiento savePlantaComportamiento: ${error}`);
            } finally {
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    }

    const getComportamientoPlanta = async () => {
        try {
            setDataExists(null);
            setDataSelected(null);
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.area_plant_list);
            const result = await TypeBehaviorService.getComportamientoPlanta(valuePlant);
            if (result.status === CODES.SUCCESS_200) {
                setDataExists(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en Plant getAreasByPlanta: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    return (
        <div>
            <h1 className='text-center title-page'>Asig. Tipo Comp. a Planta</h1>
            <div className='row justify-content-center'>
                <div className='col-md-2'>
                    <CustomSelect
                        title={'Planta'}
                        placeholder={'Selecionar planta'}
                        options={dataPlants}
                        value={valuePlant || 0}
                        onChange={(value, event) => setValuePlant(value)}
                    />
                </div>
                {/*<div className='col-md-2'>
                    <CustomSelect
                        title={'Tipo Comportamiento'}
                        placeholder={'Seleccionar Tipo Comportamiento'}
                        options={dataBehaviors}
                        value={valueBehavior || 0}
                        onChange={(value, event) => setValueBehavior(value)}
                    />
    </div>*/}
                <div className='col-md-2 d-flex justify-content-evenly align-items-end'>
                    <button className='btn btn-sm btn-primary' onClick={() => savePlantaComportamiento(1)}><AddHomeWorkIcon fontSize='small' />  Agregar</button>
                    <button className='btn btn-sm btn-danger' onClick={() => savePlantaComportamiento(2)}><DeleteSweepIcon fontSize='small' />  Retirar</button>

                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-7 col-sm-10'>
                    <BodyBehaviorAssignment
                        states={states}
                        getInfo={getInfo}
                        data={data}
                        setData={setData}
                        dataExists={dataExists}
                        dataSelected={dataSelected}
                        setDataSelected={setDataSelected}
                        id={"id_tipoComportamiento"}
                    />
                </div>
            </div>
        </div>
    )
}

export default BehaviorAssignment