import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;
const prefix = 'subArea';
const url = window.location.pathname;
const commonParams = { url: url };

class SubAreaService {
    async showAll() {
        const ruta = `${API_URL}/${prefix}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async saveOrUpdate(body) {
        const ruta = `${API_URL}/${prefix}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.post(ruta, body, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async getSubAreasByArea(id_planta, id_area) {
        const ruta = `${API_URL}/${prefix}/area/${id_planta}/${id_area}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async saveOrUpdateAreaSubArea(body) {
        const ruta = `${API_URL}/${prefix}/areas`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.post(ruta, body, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }
}

export default new SubAreaService();