export const validationMessages = {
    validation_error: "No tiene permisos para el recurso que intenta acceder",
    expired_session: "Tiempo de espera superado",
    limit_days_inspection: "Solo puede seleccionar 5 dias",
    estado: "Debe seleccionar el estado",
    planta: "Debe seleccionar una planta",
    area: "Debe seleccionar un área",
    subArea: "Debe seleccionar una sub-área",
    lugar: "Debe seleccionar un lugar",
    tipoTrabajador: "Debe seleccionar un tipo de trabajador",
    tipoInvolucrado: "Debe seleccionar un tipo de involucrado",
    tipoEvento: "Debe seleccionar un tipo de evento",
    desPlanta: "Debe ingresar el nombre de una planta",
    desArea: "Debe ingresar el nombre de una área",
    desSubArea: "Debe ingresar el nombre de una sub-área",
    desLugar: "Debe ingresar el nombre de un lugar",
    desCategoTipoCompor:"Debe ingresar el nombre de la categoria de comportamiento",
    sedes: "Debe seleccionar una o mas sedes",
    plantas: "Debe seleccionar una o mas plantas",
    assign_areas_state: "No se puede asignar/retirar áreas inactivas",
    assign_behavior_state: "No se puede asignar/retirar comportamientos inactivos",
    assign_condition_state: "No se puede asignar/retirar condiciones inactivos",
    assign_subAreas_state: "No se puede asignar/retirar sub-áreas inactivas",
    assign_places_state: "No se puede asignar/retirar lugares inactivos",
    date: "Debe ingresar una fecha",
    dateInit: "Debe ingresar una fecha de inicio valida",
    dateEnd: "Debe ingresar una fecha de fin valida",
    categoria: "Debe seleccionar una categoria",
    turno: "Debe seleccionar un turno",
    desSuceso: "Debe ingresar un suceso",
    desAccion: "Debe ingresar una acción",
    desCierre: "Debe ingresar un cierre",
    trabajadores: "Debe seleccionar algun trabajador",
    afectados: "Debe seleccionar algun afectado",
    testigos: "Debe seleccionar algun testgio",
    supervisores: "Debe seleccionar algun supevisor",
    perfil: "Debe seleccionar un perfil",
    add_category_behavior: "Seleccione un comportamiento antes de agregar una categoría",
    add_category_condition: "Seleccione una condicion antes de agregar una categoría",
};