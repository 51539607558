import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/redux/actions';
import UserService from '../../../../services/user';
import CustomModal from '../../../components/General/CustomModal';
import InformationAddPlant from '../../../components/User/AddHeadquarters/InformationAddPlant';
import { simpleAlerts } from '../../../../utils/alerts';
import { CODES } from '../../../../utils/codesHTTP';
import { validationMessages } from '../../../../message/validations';
import { titlesSpinner } from '../../../../message/titlesSpinner';
import { titlesAlerts } from '../../../../message/titlesAlerts';
import Swal from 'sweetalert2';

const AddModalStructure = (props) => {
    const { open, setOpen, dataPlants, information, } = props;

    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [dataExists, setDataExists] = useState(null);
    const [dataSelected, setDataSelected] = useState(null);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setDataExists(null);
        setDataSelected(null);
        if (information?.id_usuario > 0)
            showPlantsByUser();
    }, [information])

    const showPlantsByUser = async () => {
        try {
            setDataExists(null);
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.plantSedes_list);
            const result = await UserService.showPlantsByUser(information?.id_usuario);
            if (result.status === CODES.SUCCESS_200) {
                setDataExists(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en UserService showPlantsByUser: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const closeModal = () => {
        setDataExists(null);
        setDataSelected(null);
        setActiveTab(0);

        setOpen(false);
    }

    const saveUserPlants = async (id_estado) => {
        const body = {
            ...information,
            id_estado,
            plantas: dataSelected,
        };

        if (!body.perfil_id || body.perfil_id === 0) {
            simpleAlerts({ message: validationMessages.perfil });
            return;
        }
        if (!body.plantas || body.plantas?.length === 0) {
            simpleAlerts({ message: validationMessages.plantas });
            return;
        }
        if (!body.id_estado || body.id_estado === 0) {
            simpleAlerts({ message: validationMessages.estado });
            return;
        }

        const resultSwal = await Swal.fire({
            title: +id_estado !== 1 ? titlesAlerts.confirm_user_plants_inactive : titlesAlerts.confirm_user_plants,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                handleOpenLoader(true);
                handleTitleLoader(body.id_estado !== 1 ? titlesSpinner.userPlants_remove : titlesSpinner.userPlants_add);
                const result = await UserService.saveUserPlants(body);
                if (result.status === CODES.SUCCESS_200) {
                    simpleAlerts({ message: result?.data?.message, type: "success" });
                    closeModal();
                } else {
                    simpleAlerts({ message: result?.response?.data?.message || "Error", type: "error" });
                }
                return []
            } catch (error) {
                console.log(`Error en saveOrUpdatePlantHeadquarters: ${error}`);
            } finally {
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    }

    return (
        <CustomModal
            size={'sm'}
            open={open}
            handleClose={closeModal}
            title={'Relación Usuario - Planta'}
            subTitle={'Asociar/Retirar Plantas'}
            bodyModal={
                <InformationAddPlant
                    information={information}
                    data={dataPlants}
                    dataExists={dataExists}
                    dataSelected={dataSelected}
                    setDataSelected={setDataSelected}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            }
            buttonActions={
                (() => {
                    switch (activeTab) {
                        case 0:
                            return (
                                [
                                    <button
                                        key={'btn-save'}
                                        type={'button'}
                                        className='btn btn-sm btn-dark'
                                        onClick={() => saveUserPlants(2)}
                                    >
                                        Retirar Plantas
                                    </button>
                                ]
                            );
                        case 1:
                            return (
                                [
                                    <button
                                        key={'btn-save'}
                                        type={'button'}
                                        className='btn btn-sm btn-success'
                                        onClick={() => saveUserPlants(1)}
                                    >
                                        Agregar Plantas
                                    </button>
                                ]
                            );
                        default:
                            return null;
                    }
                })()
            }
        />
    )
}

export default AddModalStructure