// Componente para cambiar dinámicamente el título del documento
import { useEffect } from 'react';

function DynamicTitle({ title }) {
    useEffect(() => {
        document.title = `${title}`;
    }, [title]);

    return null; // Este componente no renderiza nada en el DOM
}

export default DynamicTitle;
