import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, } from '../../../../config/redux/actions';
import GeneralService from "../../../../services/general";
import PlantService from '../../../../services/plant';
import AreaService from '../../../../services/area';
import SubAreaService from '../../../../services/subArea';
import PlaceService from '../../../../services/place';
import Area from './Area';
import SubArea from './SubArea';
import Place from './Place';
import Plant from './Plant';
import { CODES } from "../../../../utils/codesHTTP";
import DynamicTitle from "../../../../utils/DynamicTitle";

const Definitions = () => {
  const dispatch = useDispatch();
  const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (index) => setActiveTab(index);

  const [states, setStates] = useState(null);
  const [dataPlants, setDataPlants] = useState(null);
  const [dataAreas, setDataAreas] = useState(null);
  const [dataSubAreas, setDataSubAreas] = useState(null);
  const [dataPlaces, setDataPlaces] = useState(null);

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    handleOpenLoader(true);
    Promise.all([
      getStates(),
      getPlants(),
      getAreas(),
      getSubAreas(),
      getPlaces(),
    ]).then(() => handleOpenLoader(false));
  }

  const getStates = async () => {
    try {
      setStates(null);
      const result = await GeneralService.showStates();
      if (result.status === CODES.SUCCESS_200) {
        setStates(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en Plant showAll: ${error}`);
    }
  }

  const getPlants = async () => {
    try {
      setDataPlants(null);
      const result = await PlantService.showAll();
      if (result.status === CODES.SUCCESS_200) {
        setDataPlants(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en Plant showAll: ${error}`);
    }
  }

  const getAreas = async () => {
    try {
      setDataAreas(null);
      const result = await AreaService.showAll();
      if (result.status === CODES.SUCCESS_200) {
        setDataAreas(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en Areas showAll: ${error}`);
    }
  }

  const getSubAreas = async () => {
    try {
      setDataSubAreas(null);
      const result = await SubAreaService.showAll();
      if (result.status === CODES.SUCCESS_200) {
        setDataSubAreas(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en SubAreas showAll: ${error}`);
    }
  }

  const getPlaces = async () => {
    try {
      setDataPlaces(null);
      const result = await PlaceService.showAll();
      if (result.status === CODES.SUCCESS_200) {
        setDataPlaces(result.data);
      }
      return []
    } catch (error) {
      console.log(`Error en Places showAll: ${error}`);
    }
  }

  return (
    <>
      <DynamicTitle title="Definición de Estructura" />
      <ul className="nav nav-underline justify-content-center">
        <li className="nav-item" onClick={() => handleChangeTab(0)}>
          <a className={`nav-link ${activeTab === 0 ? 'active' : ''}`}>Planta</a>
        </li>
        <li className="nav-item" onClick={() => handleChangeTab(1)}>
          <a className={`nav-link ${activeTab === 1 ? 'active' : ''}`}>Área</a>
        </li>
        <li className="nav-item" onClick={() => handleChangeTab(2)}>
          <a className={`nav-link ${activeTab === 2 ? 'active' : ''}`}>Sub-Área</a>
        </li>
        <li className="nav-item" onClick={() => handleChangeTab(3)}>
          <a className={`nav-link ${activeTab === 3 ? 'active' : ''}`}>Lugar</a>
        </li>
      </ul>

      <div
  className="content-container"
  style={{ width: '100%', overflow: 'hidden'}}>
  <div className={`nav-content ${activeTab === 0 ? 'nav-slide-in' : ''}`} style={{ display: activeTab === 0 ? 'block' : 'none' }}>
    <Plant
      states={states}
      data={dataPlants}
      setData={setDataPlants}
      getInfo={getPlants}
    />
  </div>
  <div className={`nav-content ${activeTab === 1 ? 'nav-slide-in' : ''}`} style={{ display: activeTab === 1 ? 'block' : 'none' }}>
    <Area
      states={states}
      data={dataAreas}
      setData={setDataAreas}
      getInfo={getAreas}
      dataPlants={dataPlants}
    />
  </div>
  <div className={`nav-content ${activeTab === 2 ? 'nav-slide-in' : ''}`} style={{ display: activeTab === 2 ? 'block' : 'none' }}>
    <SubArea
      states={states}
      data={dataSubAreas}
      setData={setDataSubAreas}
      getInfo={getSubAreas}
      dataPlants={dataPlants}
    />
  </div>
  <div className={`nav-content ${activeTab === 3 ? 'nav-slide-in' : ''}`} style={{ display: activeTab === 3 ? 'block' : 'none' }}>
    <Place
      states={states}
      data={dataPlaces}
      setData={setDataPlaces}
      getInfo={getPlaces}
      dataPlants={dataPlants}
    />
  </div>
</div>

    </>
  )
}

export default Definitions