import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import GeneralService from '../../../services/general';
import UserService from '../../../services/user';
import PlantService from '../../../services/plant';
import AddPlantsToUser from './AddPlantsToUser/AddPlantsToUser';
import CustomInputSearch from '../../components/General/CustomInputSearch';
import BodyUser from '../../components/User/BodyUser';
import DynamicTitle from '../../../utils/DynamicTitle';
import { CODES } from "../../../utils/codesHTTP";
import { titlesSpinner } from '../../../message/titlesSpinner';

const User = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [states, setStates] = useState(null);
    const [profiles, setProfiles] = useState(null);

    const [dataUsers, setDataUsers] = useState(null);
    const [dataPlants, setDataPlants] = useState(null);
    const [information, setInformation] = useState(null);
    const [search, setSearch] = useState('');

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        handleOpenLoader(true);
        Promise.all([
            showStates(),
            showProfiles(),
            showUsers(),
            showPlants(),
        ]).then(() => handleOpenLoader(false));
    }

    const showStates = async () => {
        try {
            setStates(null);
            const result = await GeneralService.showStates();
            if (result.status === CODES.SUCCESS_200) {
                setStates(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en User showAll: ${error}`);
        }
    }

    const showProfiles = async () => {
        try {
            setProfiles(null);
            const result = await GeneralService.showProfiles();
            if (result.status === CODES.SUCCESS_200) {
                setProfiles(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en User showAll: ${error}`);
        }
    }

    const showUsers = async () => {
        try {
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.user_list)
            setDataUsers(null);
            const result = await UserService.showAll();
            if (result.status === CODES.SUCCESS_200) {
                setDataUsers(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en User showAll: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null)
        }
    }

    const showPlants = async () => {
        try {
            setDataPlants(null);
            const params = {
                id_estado: 1,
            };
            const result = await PlantService.showAll(params);
            if (result.status === CODES.SUCCESS_200) {
                setDataPlants(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en Plant showAll: ${error}`);
        }
    }

    const handleOpenModal = ({ info = null }) => {
        setInformation(info);
        setOpen(true);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            showUsers();
        }
    };

    return (
        <div>
            <DynamicTitle title="Mantenimiento de usuarios" />
            <AddPlantsToUser
                open={open}
                setOpen={setOpen}
                dataPlants={dataPlants}
                information={information}
            />
            <h1 className='text-center title-page'>Mantenimiento de Usuarios</h1>
            <div className='row justify-content-center gap-2'>
                <div className='col-lg-5 col-md-5 col-sm-10'>
                    <CustomInputSearch
                        placeholder="Buscar trabajador"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onClick={showUsers}
                        handleKeyPress={handleKeyPress}
                    />
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-lg-8 col-md-9 col-sm-11'>
                    <BodyUser
                        handleOpenModal={handleOpenModal}
                        getInfo={showUsers}
                        search={search}
                        states={states}
                        profiles={profiles}
                        data={dataUsers}
                        setData={setDataUsers}
                        id={"id_usuario"}
                    />
                </div>
            </div>
        </div>
    )
}

export default User;