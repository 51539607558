import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BodyBehavior from '../../../components/Definitions/Concepts/Behavior/BodyBehavior'
import CustomInput from '../../../components/General/CustomInput';
import _ from 'lodash';

const newInfo = {
    desComportamiento: null,
    estado_id: 1,
    edit: 1,
}

const Behavior = (props) => {
    const { states, data, setData, getInfo } = props;

    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const addComportamiento = () => {
        let updData = _.cloneDeep(data);
        updData?.push(newInfo);
        return setData(updData);
    }

    const handleSearch = (search) => {
        setSearch(search);
        const filtered = data.filter(item =>
            item.desComportamiento.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredData(filtered);
    };

    return (
        <div>
            <h1 className='text-center title-page'>Definición de Comportamiento</h1>
            <div className='row justify-content-center'>
                <div className='col-md-5'>
                    <CustomInput
                        placeholder={'Buscar comportamiento'}
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                <div className='col-md-2 d-flex justify-content-evenly align-items-end'>
                    <button className='btn btn-sm btn-primary' type='button' onClick={addComportamiento}><AddCircleIcon fontSize='small' /> Crear</button>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-7 col-sm-10'>
                    <BodyBehavior
                        states={states}
                        data={filteredData.length > 0 ? filteredData : data}
                        setData={setData}
                        getInfo={getInfo}

                    />
                </div>
            </div>
        </div>
    )
}

export default Behavior