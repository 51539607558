import React from 'react'
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';

const columns = [
    { id: 'actions', label: 'actions', width: 10, align: 'center', },
    { id: 'numdoc', label: 'numdoc', width: 20, align: 'center', },
    { id: 'trabajador', label: 'trabajador', width: 150, },
];

const BodyWorkersAdd = (props) => {
    const { data, setData, } = props;

    // /** inicio ordenamiento de columnas */
    const handleRemoveWorkers = (idx, info) => {
        let updData = _.cloneDeep(data);
        updData = updData?.filter(e => e.numdoc !== info.numdoc);
        setData(updData);
    }

    return (
        <div className="table-responsive">
            <table className="table table-bordered">
                <tbody>
                    {data?.map((row, idxRow) => {
                        return (
                            <tr
                                key={`row-${idxRow}`}
                            >
                                {columns?.map((col) => {
                                    const value = row[col.id]
                                    return (
                                        <td
                                            key={`col-${col.id}`}
                                            className={`${col.align}`}
                                            style={{
                                                width: `${col.width}px`,
                                                backgroundColor: 'transparent',
                                                borderLeft: 'none',
                                                borderRight: 'none',
                                            }}
                                        >
                                            {col.id === "actions" ?
                                                <IconButton
                                                    aria-label="delete"
                                                    size="small"
                                                    title="Retirar trabajador"
                                                    onClick={() => handleRemoveWorkers(idxRow, row)}
                                                    style={{ border: 'solid 1px', borderRadius: '5px', padding: '1px', }}
                                                    color='error'
                                                >
                                                    <DeleteIcon className="icon-button-table" color={'error'} />
                                                </IconButton>
                                                : value}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default BodyWorkersAdd;