import React, { useState, useEffect, useRef } from 'react';
import { Select } from "antd";
const { Option } = Select;

const CustomSelectSingleLine = (props) => {
    const { mode, title, readonly, placeholder, width, size, options, value, name, onChange, required = false } = props;
    const titleRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        if (titleRef.current) {
            setIsTruncated(titleRef.current.scrollWidth > titleRef.current.clientWidth);
        }
    }, [title]);

    return (
        <div className="custom-input-group-single-line">
            {title &&
                <label
                    ref={titleRef}
                    className={`form-label title-legend ${isTruncated ? 'truncated' : ''}`}
                    title={title}
                >
                    {`${title}:`}
                </label>
            }
            <Select
                mode={mode || "single"}
                maxTagCount={'responsive'}
                disabled={readonly || false}
                className="select"
                size={size || 'middle'}
                placeholder={placeholder || 'Seleccionar'}
                optionFilterProp="children"
                showSearch
                name={name || ''}
                popupClassName='my-drop-down'
                value={value || 0}
                onChange={onChange}
                style={{ width: "100%" }}
                required={required || false}
            >
                <Option value={0}><em>Seleccionar</em></Option>
                {options?.map((opt, idx) => {
                    return (
                        <Option key={opt?.value?.toString() + opt?.label?.replace(/\s+/g, '')} value={opt?.value}>
                            {opt?.label}
                        </Option>
                    )
                })}
            </Select>
        </div>
    );
};

export default CustomSelectSingleLine