export const titlesAlerts = {
    confirm_plant: "Seguro de guardar la planta! ",
    confirm_plant_inactive: "Seguro de desactivar la planta! ",
    confirm_plant_headquarters: "Seguro de agregar las sedes a la planta! ",
    confirm_plant_headquarters_inactive: "Seguro de retirar las sedes de la planta! ",

    confirm_area: "Seguro de guardar el área! ",
    confirm_area_inactive: "Seguro de desactivar el área! ",
    confirm_area_plant: "Seguro de agregar las areas a la planta! ",
    confirm_area_plant_inactive: "Seguro de retirar las areas a la planta! ",

    confirm_subArea: "Seguro de guardar la sub-área! ",
    confirm_subArea_inactive: "Seguro de desactivar la sub-área! ",
    confirm_subArea_area: "Seguro de agregar las sub-areas al área! ",
    confirm_subArea_area_inactive: "Seguro de retirar las sub-areas del área! ",

    confirm_place: "Seguro de guardar el lugar! ",
    confirm_place_inactive: "Seguro de desactivar el lugar! ",
    confirm_place_subArea: "Seguro de agregar los lugares a la sub-área! ",
    confirm_place_subArea_inactive: "Seguro de retirar los lugares a la sub-área! ",

    confirm_unsafe_behavior: "Seguro de guardar la información del comportamiento inseguro! ",
    confirm_unsafe_condition: "Seguro de guardar la información del condición insegura! ",
    not_found_unsafe_condition: "No se encontro información de ! ",
    confirm_accident: "Seguro de guardar el accident o incidente! ",
    
    confirm_user_plants: "Seguro de agregar las plantas al usuario! ",
    confirm_user_plants_inactive: "Seguro de retirar las plantas del usuario! ",

    confirm_user: "Seguro de guardar el usuario! ",
    confirm_user_inactive: "Seguro de desactivar el usuario! ",

    confirm_behavior: "Seguro de guardar comportamiento! ",
    confirm_behavior_inactive: "Seguro de desactivar comportamiento! ",
    confirm_behavior_plant_inactive: "Seguro de retirar los comportamientos a la planta! ",
    confirm_behavior_plant: "Seguro de agregar los comportamientos a la planta! ",

    confirm_type_event_plant_inactive: "Seguro de retirar los tipos de evento del área! ",
    confirm_type_event_plant: "Seguro de agregar los tipos de evento del área! ",

    confirm_type_event: "Seguro de guardar tipo de evento! ",
    confirm_type_event_inactive: "Seguro de desactivar tipo de evento! ",

    confirm_unsafe_condition_inactive: "Seguro de Seguro de desactivar condición insegura! ",
    confirm_condition_category_inactive: "Seguro de desactivar categoria condicion! ",
    confirm_condition: "Seguro de guardar condicion insegura! ",
    confirm_condition_category: "Seguro de guardar categoria de condicion! ",
    confirm_condition_plant_inactive: "Seguro de retirar las condiciones a la planta! ",
    confirm_condicion_plant: "Seguro de agregar las condiciones a la planta! ",

    confirm_behavior_category: "Seguro de guardar categoria de comportamiento! ",
    confirm_behavior_category_inactive: "Seguro de desactivar categoria comportamiento! ",

    confirm_inspection: "Seguro de guardar la inspección! ",

    log_out: "Cerrando sesion",
    notfound_worker: "Trabajador no encontrado!",
};