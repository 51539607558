import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;
const prefix = "accident-incident";
const url = window.location.pathname;
const commonParams = { url: url };

class AccidentService {
    async showAll() {
        const ruta = `${API_URL}/${prefix}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async saveAccident(body) {
        const ruta = `${API_URL}/${prefix}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.post(ruta, body, {
            headers: {
                ...headers,
                'Content-Type': 'multipart/form-data',
            },
            params: params,
        }).catch((error) => {
            return error;
        });
    }
}

export default new AccidentService();