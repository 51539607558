import React, { useEffect, useState } from 'react'
import BodyWorkersAdd from '../../Workers/BodyWorkersAdd';
import CustomNavPagination from '../../General/CustomNavPagination';

const Involved = ({ titles, involved, setInvolved, info, setInfo, }) => {
    const [dataWorker, setDataWorker] = useState(null);

    // Primer useEffect
    useEffect(() => {
        let currentData = null;
        if (+involved === 1) {
            currentData = info.afectados;
        } else if (+involved === 2) {
            currentData = info.testigos;
        } else if (+involved === 3) {
            currentData = info.supervisores;
        }
        if (dataWorker !== currentData) {
            return setDataWorker(currentData);
        }
    }, [info, involved]);

    // Segundo useEffect
    useEffect(() => {
        if (dataWorker !== null) {
            if (+involved === 1 && dataWorker !== info.afectados) {
                setInfo({ ...info, afectados: dataWorker });
            } else if (+involved === 2 && dataWorker !== info.testigos) {
                setInfo({ ...info, testigos: dataWorker });
            } else if (+involved === 3 && dataWorker !== info.supervisores) {
                setInfo({ ...info, supervisores: dataWorker });
            }
        }
    }, [dataWorker]);

    return (
        <div className="nav-pagination-container">
            <div className="nav-pagination nav-pagination-effect-scale nav-pagination-theme-1">
                {titles?.map((title, idxTitle) => {
                    return (
                        <CustomNavPagination
                            key={`title-involved-${idxTitle}`}
                            id={title.value}
                            title={title.label}
                            value={involved}
                            setValue={setInvolved}
                        />
                    );
                })}

                <ul>
                    {titles?.map((title, idxTitle) => {
                        return (
                            <li key={`body-involved-${idxTitle}`} className={`tab-content tab-content-${title.value}`}>
                                {+involved === +title?.value &&
                                    <BodyWorkersAdd
                                        data={dataWorker}
                                        setData={setDataWorker}
                                    />
                                }
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Involved