import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../../config/redux/actions';
import { ButtonGroup, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ConstructionIcon from '@mui/icons-material/Construction';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { sortDataList, getValueFromPath } from "../../../../../utils/function";
import { CODES } from '../../../../../utils/codesHTTP';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { titlesAlerts } from '../../../../../message/titlesAlerts';
import { titlesSpinner } from '../../../../../message/titlesSpinner';
import { simpleAlerts } from '../../../../../utils/alerts';
import CustomInput from '../../../General/CustomInput';
import CustomSelect from '../../../General/CustomSelect';
import TypeBehaviorService from '../../../../../services/typeBehavior';

const columns = [
    { id: '#', label: '#', width: 20, align: 'center', },
    { id: 'desComportamiento', label: <><FormatListNumberedIcon key="icon" style={{ fontSize: "18px", marginRight: "10px", }} />Comportamiento</>, width: 150, align: 'left', order: true, type: "text", edit: true, },
    { id: 'estado', label: <><FormatListNumberedIcon key="icon" style={{ fontSize: "18px", marginRight: "10px", }} />Estado</>, width: 100, align: 'left', order: true, type: "select", edit: true, },
    { id: 'actions', label: <ConstructionIcon key="icon" style={{ fontSize: "18px", }} />, width: 20, align: 'center', },
];
const columnsMap = {
    estado: 'estado.desEstado',
};
const BodyBehavior = (props) => {
    const { states, getInfo, data, setData } = props;

    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    // /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        key = (key === "estado" ? "estado_id" : key);
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const srtdData = sortDataList(data, sortConfigTable);
    // /** fin ordenamiento de columnas */


    const handleOnChange = (index, info, key, value) => {
        let updData = _.cloneDeep(data);
      
        const idxTmp = updData.findIndex(e => +e.id_tipoComportamiento === +info.id_tipoComportamiento);
        const idx = (idxTmp === -1 ? index : idxTmp);

        if (key === 'edit' && value === 0) {
            // Eliminar la fila si se cancela la edición
            updData.splice(idx, 1); 
        } else {
            // Actualizar los datos según la edición
            if (key === 'estado') {
                updData[idx][key] = states?.find(e => +e.value === value);
                updData[idx]["estado_id"] = value;
            } else {
                updData[idx][key] = value;
            }
        }
      
        setData(updData); // Actualizar el estado de los datos
    }
    
    const saveBehavior = async (idx, info, estado_id) => {
        if (!info?.id_tipoComportamiento && estado_id === 2) {
            let updData = _.cloneDeep(data);
            updData = updData?.filter((e, index) => index !== idx);
            setData(updData);
        } else {
            const resultSwal = await Swal.fire({
                title: `${+estado_id === 2 ? titlesAlerts.confirm_behavior_inactive : titlesAlerts.confirm_behavior} (${info?.desComportamiento})`,
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#229954", // "#3085d6",
                confirmButtonText: "Si!",
                cancelButtonColor: "#d33",
                reverseButtons: true,
            });

            if (resultSwal.isConfirmed) {
                try {
                    info = { ...info, estado_id };
                    handleOpenLoader(true);
                    handleTitleLoader(titlesSpinner.behavior_save);
                    const result = await TypeBehaviorService.saveOrUpdate(info);
                    if (result.status === CODES.SUCCESS_200) {
                        getInfo();
                        simpleAlerts({ message: (result?.data?.message || ""), type: "success" })
                    } else {
                        simpleAlerts({ message: (result?.response?.data?.message || ""), type: "error" })
                    }
                    return []
                } catch (error) {
                    console.log(`Error en Behavior saveOrUpdate: ${error}`);
                } finally {
                    handleOpenLoader(false);
                    handleTitleLoader(null);
                }
            }
        }
    }

    return (
        <div className="frame-table">
            <div className="table-responsive body-table">
                <table className="table table-hover table-bordered">
                    <thead className='sticky-top table-dark header-table z-1'>
                        <tr>
                            {columns?.map((col) => {
                                return (
                                    <th
                                        key={`header-${col.id}`}
                                        className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                                        style={{ width: `${col.width}px`, maxWidth: `${col.width}px`, }}
                                        onClick={() => col.order && handleSortTable(col.id)}
                                    >
                                        {col.label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {srtdData?.map((row, idxRow) => {
                            return (
                                <tr key={"row" + idxRow}
                                    className={`${+row.estado_id !== 1 && 'table-danger'}`}
                                >
                                    {columns.map((col, idxCol) => {
                                        const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                        return (
                                            <td key={'col' + idxCol} className={`${col.align}`}>
                                                {col.id === "#" ?
                                                    idxRow + 1
                                                    : +row.edit === 1 ?
                                                        (col.id === "actions" ?
                                                            <ButtonGroup>
                                                                <IconButton
                                                                    aria-label="upload"
                                                                    size="small"
                                                                    title="Guardar Comportamiento"
                                                                    onClick={() => saveBehavior(idxRow, row, row.estado_id)}
                                                                >
                                                                    <SaveIcon className="icon-button-table" color={'success'} />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    title="Cancelar edición de Comportamiento"
                                                                    onClick={() => handleOnChange(idxRow, row, 'edit', 0)}
                                                                >
                                                                    <CancelIcon className="icon-button-table" color={'warning'} />
                                                                </IconButton>
                                                            </ButtonGroup>
                                                            : col.edit ?
                                                                (col.type === 'select' ?
                                                                    <CustomSelect
                                                                        placeholder={'Estado'}
                                                                        options={states}
                                                                        value={value}
                                                                        onChange={(value, event) => handleOnChange(idxRow, row, 'estado', +value)}
                                                                    />
                                                                    : <CustomInput
                                                                        placeholder={'Nombre de Comportamiento'}
                                                                        value={value}
                                                                        type={col.type}
                                                                        onChange={(e) => handleOnChange(idxRow, row, col.id, e.target.value)}
                                                                    />
                                                                )
                                                                : value)
                                                        : (col.id === "actions" ?
                                                            <ButtonGroup>
                                                               {/* <IconButton
                                                                    aria-label="upload"
                                                                    size="small"
                                                                    title="Ver Sedes"
                                                                    onClick={() => handleOpenModal({ info: row })}
                                                                >
                                                                    <ApartmentIcon className="icon-button-table" color={'success'} />
                                                                </IconButton>*/}
                                                                <IconButton
                                                                    aria-label="upload"
                                                                    size="small"
                                                                    title="Ver Comportamiento"
                                                                    onClick={() => handleOnChange(idxRow, row, 'edit', 1)}
                                                                >
                                                                    <EditIcon className="icon-button-table" color={'primary'} />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    title="Desactivar Comportamiento"
                                                                    onClick={() => saveBehavior(idxRow, row, 2)}
                                                                >
                                                                    <DeleteIcon className="icon-button-table" color={'error'} />
                                                                </IconButton>
                                                            </ButtonGroup>
                                                            : value)
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {/* <CustomPagination
        // totalPages={}
        // page={}
        // setPage={}
        /> */}
        </div>
    )
}

export default BodyBehavior;