import React from 'react';

const CustomInputInfo = (props) => {
    const { title, value, name, } = props;

    return (
        <div>
            <label className="form-label title-legend">
                {title}
            </label>
            <label
                className="form-control text"
                name={name || ''}
                style={{
                    height: '1.9rem',
                    backgroundColor: "#D5DBDB",
                }}
            >
                {value || ''}
            </label>
        </div>
    );
};

export default CustomInputInfo;