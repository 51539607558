import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/redux/actions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GeneralService from '../../../../services/general';
import BodyPlant from '../../../components/Definitions/Structure/Plant/BodyPlant';
import AddModalStructure from './AddModalStructure/AddModalStructure';
import { CODES } from "../../../../utils/codesHTTP";
import _ from 'lodash';

const newInfo = {
    desPlanta: null,
    rutaMapa: null,
    estado_id: 1,
    estado: { id_estado: 1 },
    edit: 1,
}

const Plant = (props) => {
    const { states, data, setData, getInfo } = props;
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [information, setInformation] = useState(null);
    const [companies, setCompanies] = useState(null);

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        handleOpenLoader(true);
        Promise.all([
            showCompanies(),
        ]).then(() => handleOpenLoader(false));
    }

    const showCompanies = async () => {
        try {
            setCompanies(null);
            const result = await GeneralService.showCompanies();
            if (result.status === CODES.SUCCESS_200) {
                setCompanies(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en showCompanies: ${error}`);
        }
    }

    const handleOpenModal = ({ info = null }) => {
        setInformation(info);
        setOpen(true);
    }

    const addSubArea = () => {
        let updData = _.cloneDeep(data);
        updData?.push(newInfo);
        return setData(updData);
    }

    return (
        <div>
            <AddModalStructure
                open={open}
                setOpen={setOpen}
                companies={companies}
                states={states}
                information={information}
                setInformation={setInformation}
            />
            <h1 className='text-center title-page'>Definición de Plantas</h1>
            <div className='row justify-content-center'>
                <div className='col-md-3 d-flex justify-content-evenly align-items-end'>
                    <button className='btn btn-sm btn-success' type='button' onClick={addSubArea}>
                        <AddCircleIcon fontSize='small' />  Crear Planta
                    </button>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-lg-6 col-md-9 col-sm-11'>
                    <BodyPlant
                        states={states}
                        getInfo={getInfo}
                        data={data}
                        setData={setData}
                        handleOpenModal={handleOpenModal}
                    />
                </div>
            </div>
        </div>
    )
}

export default Plant