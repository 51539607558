import React, { useEffect, useState } from 'react';
import BodyWorkersList from "../BodyWorkersList";
import _ from 'lodash';

const InformationWorker = ({
    typeSelection,
    dataWorkers, page, setPage, totalPage,
    valueTypeWorker, valueTypeInvolved,
    workersSelected, setWorkersSelected,
}) => {
    const handleAddWorkers = ({ info }) => {
        let updData = _.cloneDeep(workersSelected);
        if (!updData) {
            updData = []
        }
        if (typeSelection == "SINGLE") {
            updData[0] = info;
        } else {
            info["tipoTrabajador_id"] = valueTypeWorker;

            if (valueTypeInvolved) {
                info["tipoInvolucrado_id"] = valueTypeInvolved;
            }
            updData?.push(info);
        }
        setWorkersSelected(updData);
    }

    return (
        <div className='row'>
            <div className='col-12'>
                <BodyWorkersList
                    page={page}
                    setPage={setPage}
                    totalPage={totalPage}
                    data={dataWorkers}
                    dataSelected={workersSelected}
                    handleAdd={handleAddWorkers}
                />
            </div>
        </div>
    )
}

export default InformationWorker