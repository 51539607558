import React from 'react'
import CustomSelect from '../../../../General/CustomSelect';
import CustomInput from '../../../../General/CustomInput';
import BodyPlantHeadquarters from '../AddHeadquarters/BodyPlantHeadquarters';

const InformationPlant = (props) => {
    const { companies, clients, units, states,
        valueCompany, setValueCompany,
        valueClient, setValueClient,
        valueUnit, setValueUnit,
        information,
        data, dataExists, dataSelected, setDataSelected,
        activeTab, setActiveTab,
    } = props;

    const handleChangeTab = (index) => setActiveTab(index);

    return (
        <div>
            <div className='row gap-2'>
                <div className='col-md-6 col-sm-12'>
                    <CustomInput
                        title={"Descripción de la Planta"}
                        value={information?.desPlanta}
                        name={"desPlanta"}
                        onChange={null}
                    />
                </div>
                <div className='col-md-6 col-sm-12'>
                    <CustomSelect
                        placeholder={'Estado'}
                        options={states}
                        value={information?.estado_id}
                        onChange={null}
                    />
                </div>
            </div>

            <ul className="nav nav-underline small mt-2">
                <li className="nav-item" onClick={() => handleChangeTab(0)}>
                    <a className={`nav-link item-small ${activeTab === 0 ? 'active' : ''}`}>Listar</a>
                </li>
                <li className="nav-item" onClick={() => handleChangeTab(1)}>
                    <a className={`nav-link item-small ${activeTab === 1 ? 'active' : ''}`}>Agregar</a>
                </li>
            </ul>

            {activeTab === 1 &&
                <div className='row'>
                    <div className='col-12'>
                        <CustomSelect
                            title="Empresa"
                            value={valueCompany}
                            options={companies}
                            onChange={(value, event) => setValueCompany(value)}
                        />
                    </div>
                    <div className='col-12'>
                        <CustomSelect
                            title="Clientes"
                            value={valueClient}
                            options={clients}
                            onChange={(value, event) => setValueClient(value)}
                        />
                    </div>
                    <div className='col-12'>
                        <CustomSelect
                            title="Unidades"
                            value={valueUnit}
                            options={units}
                            onChange={(value, event) => setValueUnit(value)}
                        />
                    </div>
                </div>
            }
            <BodyPlantHeadquarters
                data={activeTab > 0 ? data : dataExists}
                dataExists={dataExists}
                dataSelected={dataSelected}
                setDataSelected={setDataSelected}
                activeTab={activeTab}
                id={"id_sede"}
            />
        </div>
    )
}

export default InformationPlant