import React, { useState, useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/redux/actions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AreaService from '../../../../services/area';
import SubAreaService from '../../../../services/subArea';
import BodySubArea from '../../../components/Definitions/Structure/SubArea/BodySubArea';
import CustomSelect from '../../../components/General/CustomSelect'
import { CODES } from '../../../../utils/codesHTTP';
import { validationMessages } from '../../../../message/validations';
import { simpleAlerts } from '../../../../utils/alerts';
import Swal from 'sweetalert2';
import { titlesSpinner } from '../../../../message/titlesSpinner';
import { titlesAlerts } from '../../../../message/titlesAlerts';
import { errorsMessages } from '../../../../message/errors';
import _ from 'lodash';

const newInfo = {
    desSubArea: null,
    estado_id: 1,
    edit: 1,
}

const SubArea = (props) => {
    const { states, data, setData, getInfo, dataPlants } = props;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [dataSelected, setDataSelected] = useState(null);
    const [dataExists, setDataExists] = useState(null);

    const [dataAreas, setDataAreas] = useState(null);
    const [dataSubAreas, setDataSubAreas] = useState(null);
    const [valuePlant, setValuePlant] = useState(null);
    const [valueArea, setValueArea] = useState(null);

    useEffect(() => {
        setDataAreas(null);
        setValueArea(null);
        if (valuePlant > 0) {
            getAreas();
        }
    }, [valuePlant])

    useEffect(() => {
        setDataExists();
        if (valueArea > 0) {
            getAreaSubAreas();
        }
    }, [valueArea])

    const getAreas = async () => {
        try {
            setDataAreas(null);
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.area_plant_list);
            const result = await AreaService.getAreasByPlanta(valuePlant);
            if (result.status === CODES.SUCCESS_200) {
                setDataAreas(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en Area getAreasByPlanta: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const getAreaSubAreas = async () => {
        try {
            setDataExists(null);
            setDataSelected(null);
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.subArea_area_list);
            const result = await SubAreaService.getSubAreasByArea(valuePlant, valueArea);
            if (result.status === CODES.SUCCESS_200) {
                setDataExists(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en Area showAreasByPlanta: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const addSubArea = () => {
        let updData = _.cloneDeep(dataSubAreas);
        updData?.push(newInfo);
        return setDataSubAreas(updData);
    }

    const saveAreaSubArea = async (id_estado) => {
        const body = {
            id_planta: valuePlant,
            id_area: valueArea,
            id_estado: id_estado,
            subAreas: dataSelected,
        }
        if (!body.id_planta || body.id_planta === 0) {
            simpleAlerts({ message: validationMessages.planta });
            return;
        }
        if (!body.id_area || body.id_area === 0) {
            simpleAlerts({ message: validationMessages.area });
            return;
        }

        const resultSwal = await Swal.fire({
            title: id_estado !== 1 ? titlesAlerts.confirm_subArea_area_inactive : titlesAlerts.confirm_subArea_area,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                handleOpenLoader(true);
                handleTitleLoader(id_estado !== 1 ? titlesSpinner.subAreaArea_remove : titlesSpinner.subAreaArea_add);
                const result = await SubAreaService.saveOrUpdateAreaSubArea(body);
                if (result.status === CODES.SUCCESS_200) {
                    getAreaSubAreas();
                    simpleAlerts({ message: result?.data?.message, type: "success", })
                } else {
                    simpleAlerts({ message: (result?.response?.data?.message || errorsMessages.subArea_save), type: "error" })
                }
                return []
            } catch (error) {
                console.log(`Error en sub-área saveOrUpdate: ${error}`);
            } finally {
                setDataSelected();
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    }

    return (
        <div>
            <h1 className='text-center title-page'>Definición Sub-Área</h1>
            <div className='row justify-content-center gap-sm-3'>
                <div className='col-md-3 col-sm-8'>
                    <CustomSelect
                        title={'Planta'}
                        placeholder={'Selecionar Planta'}
                        options={dataPlants}
                        value={valuePlant || 0}
                        onChange={(value, event) => setValuePlant(value)}
                    />
                </div>
                <div className='col-md-3 col-sm-8'>
                    <CustomSelect
                        title={'Área'}
                        placeholder={'Selecionar área'}
                        options={dataAreas}
                        value={valueArea || 0}
                        onChange={(value, event) => setValueArea(value)}
                    />
                </div>
                <div className='col-md-6 col-sm-8 d-flex justify-content-evenly align-items-end'>
                    <button className='btn btn-sm btn-success' onClick={addSubArea}><AddCircleIcon fontSize='small' />  Crear Sub-Área</button>
                    <button className='btn btn-sm btn-outline-success' onClick={() => saveAreaSubArea(1)}><AddHomeWorkIcon fontSize='small' />  Agregar</button>
                    <button className='btn btn-sm btn-outline-danger' onClick={() => saveAreaSubArea(2)}><DeleteSweepIcon fontSize='small' />  Retirar</button>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-8 col-sm-10'>
                    <BodySubArea
                        states={states}
                        getInfo={getInfo}
                        data={data}
                        setData={setData}
                        dataExists={dataExists}
                        dataSelected={dataSelected}
                        setDataSelected={setDataSelected}
                        id={"id_subArea"}
                    />
                </div>
            </div>
        </div>
    )
}

export default SubArea