import React, { } from 'react';

const CustomCheck = (props) => {
    const { id, label, handleChangeCheck, info = null, checked = false } = props;

    return (
        <div className="checkbox-custom"
            style={{ display: 'flex', justifyContent: 'center' }}
        >
            <input
                type="checkbox"
                name="check"
                id={id}
                checked={checked}
                onChange={(e) => handleChangeCheck(e.target.checked, info)}
            />
            <label htmlFor={id}>{label}</label>
        </div>
    );
};

export default CustomCheck;