import React from 'react';

const CustomTextArea = (props) => {
    const { title, readonly, placeholder, value, required, name, onChange, style, handleKeyPress, propsInput } = props;

    return (
        <div>
            {title &&
                <label className="form-label title-legend">
                    {title}
                </label>
            }
            <textarea
                className="form-control text"
                onChange={onChange}
                name={name || ''}
                placeholder={placeholder || ''}
                readOnly={readonly || false}
                required={required || false}
                {...propsInput}  // Propiedades adicionales
                style={{ ...style }}
                onKeyPress={handleKeyPress}
                value={value || ''}
            >
                {value || ''}
            </textarea>
        </div>
    );
};

export default CustomTextArea;