import React, { useState, useEffect, } from 'react';
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../config/redux/actions';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AreaService from '../../../../services/area';
import SubAreaService from '../../../../services/subArea';
import PlaceService from '../../../../services/place';
import BodyPlace from '../../../components/Definitions/Structure/Place/BodyPlace';
import CustomSelect from '../../../components/General/CustomSelect'
import { CODES } from '../../../../utils/codesHTTP';
import { titlesSpinner } from '../../../../message/titlesSpinner';
import { validationMessages } from '../../../../message/validations';
import { titlesAlerts } from '../../../../message/titlesAlerts';
import { errorsMessages } from '../../../../message/errors';
import { simpleAlerts } from '../../../../utils/alerts';
import Swal from 'sweetalert2';
import _ from 'lodash';

const newInfo = {
    desLugar: null,
    estado: null,
    edit: 1,
}

const Place = (props) => {
    const { states, data, setData, getInfo, dataPlants } = props;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [dataSelected, setDataSelected] = useState(null);
    const [dataExists, setDataExists] = useState(null);

    const [dataAreas, setDataAreas] = useState(null);
    const [dataSubAreas, setDataSubAreas] = useState(null);
    const [valuePlant, setValuePlant] = useState(null);
    const [valueArea, setValueArea] = useState(null);
    const [valueSubArea, setValueSubArea] = useState(null);

    useEffect(() => {
        setDataAreas(null);
        setDataSubAreas(null);
        setValueArea(null);
        setValueSubArea(null);
        setDataExists(null);
        setDataSelected(null);
    }, [])

    useEffect(() => {
        setDataAreas(null);
        setValueArea(null);
        setDataSubAreas(null);
        setValueSubArea(null);
        setDataExists(null);
        setDataSelected(null);
        if (valuePlant > 0) {
            getAreas();
        }
    }, [valuePlant])

    useEffect(() => {
        setDataSubAreas(null);
        setValueSubArea(null);
        setDataExists(null);
        setDataSelected(null);
        if (valueArea > 0) {
            getAreaSubAreas();
        }
    }, [valueArea])

    useEffect(() => {
        setDataExists(null);
        setDataExists(null);
        setDataSelected(null);
        if (valueSubArea > 0) {
            getLugaresBySubAreas();
        }
    }, [valueSubArea])

    const getAreas = async () => {
        try {
            setDataAreas(null);
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.area_plant_list);
            const result = await AreaService.getAreasByPlanta(valuePlant);
            if (result.status === CODES.SUCCESS_200) {
                setDataAreas(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en Area getAreasByPlanta: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const getAreaSubAreas = async () => {
        try {
            setDataSubAreas(null);
            setDataSelected(null);
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.subArea_area_list);
            const result = await SubAreaService.getSubAreasByArea(valuePlant, valueArea);
            if (result.status === CODES.SUCCESS_200) {
                setDataSubAreas(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en Area showAreasByPlanta: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const getLugaresBySubAreas = async () => {
        try {
            setDataExists(null);
            setDataSelected(null);
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.lugar_subArea_list);
            const result = await PlaceService.getLugaresBySubAreas(valuePlant, valueArea, valueSubArea);
            if (result.status === CODES.SUCCESS_200) {
                setDataExists(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en Lugare showPlacesBySubArea: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const addPlace = () => {
        let updData = _.cloneDeep(data);
        updData?.push(newInfo);
        return setData(updData);
    }

    const saveSubAreaLugar = async (id_estado) => {
        const body = {
            id_planta: valuePlant,
            id_area: valueArea,
            id_subArea: valueSubArea,
            id_estado: id_estado,
            lugares: dataSelected,
        }

        if (dataSelected?.find(e => +e.estado_id === 2)) {
            simpleAlerts({ message: validationMessages.assign_places_state });
            return;
        }
        if (!body.id_planta || body.id_planta === 0) {
            simpleAlerts({ message: validationMessages.planta });
            return;
        }
        if (!body.id_area || body.id_area === 0) {
            simpleAlerts({ message: validationMessages.area });
            return;
        }
        if (!body.id_subArea || body.id_subArea === 0) {
            simpleAlerts({ message: validationMessages.subArea });
            return;
        }

        const resultSwal = await Swal.fire({
            title: id_estado !== 1 ? titlesAlerts.confirm_place_subArea_inactive : titlesAlerts.confirm_place_subArea,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                handleOpenLoader(true);
                handleTitleLoader(id_estado !== 1 ? titlesSpinner.subAreaArea_remove : titlesSpinner.subAreaArea_add);
                const result = await PlaceService.saveOrUpdateSubAreaLugar(body);
                if (result.status === CODES.SUCCESS_200) {
                    getLugaresBySubAreas();
                    simpleAlerts({ message: result?.data?.message, type: "success", })
                } else {
                    simpleAlerts({ message: (result?.response?.data?.message || errorsMessages.subArea_save), type: "error" })
                    handleOpenLoader(false);
                    handleTitleLoader(null);
                }
                return []
            } catch (error) {
                console.log(`Error en sub-área saveOrUpdate: ${error}`);
            }
        }
    }

    return (
        <div>
            <h1 className='text-center title-page'>Definición Lugar de Trabajo</h1>
            <div className='row justify-content-center gap-sm-2'>
                <div className='col-md-3 col-sm-8'>
                    <CustomSelect
                        title={'Planta'}
                        placeholder={'Selecionar Planta'}
                        options={dataPlants}
                        value={valuePlant || 0}
                        onChange={(value, event) => setValuePlant(value)}
                    />
                </div>
                <div className='col-md-3 col-sm-8'>
                    <CustomSelect
                        title={'Área'}
                        placeholder={'Selecionar Área'}
                        options={dataAreas}
                        value={valueArea || 0}
                        onChange={(value, event) => setValueArea(value)}
                    />
                </div>
                <div className='col-md-3 col-sm-8'>
                    <CustomSelect
                        title={'Sub-Área'}
                        placeholder={'Selecionar Sub-Área'}
                        options={dataSubAreas}
                        value={valueSubArea || 0}
                        onChange={(value, event) => setValueSubArea(value)}
                    />
                </div>
                <div className='col-md-6 col-sm-8 d-flex justify-content-evenly align-items-end'>
                    <button className='btn btn-sm btn-success' onClick={addPlace}><AddCircleIcon fontSize='small' />  Crear Lugar</button>
                    <button className='btn btn-sm btn-outline-success' onClick={() => saveSubAreaLugar(1)}><AddHomeWorkIcon fontSize='small' />  Agregar</button>
                    <button className='btn btn-sm btn-outline-danger' onClick={() => saveSubAreaLugar(2)}><DeleteSweepIcon fontSize='small' />  Retirar</button>
                </div>
            </div>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-7 col-sm-10'>
                    <BodyPlace
                        states={states}
                        getInfo={getInfo}
                        data={data}
                        setData={setData}
                        dataExists={dataExists}
                        dataSelected={dataSelected}
                        setDataSelected={setDataSelected}
                        id={"id_lugar"}
                    />
                </div>
            </div>
        </div>
    )
}

export default Place