// actions.js
export const setSelectedInfo = (info) => ({
    type: 'SET_SELECTED_INFO',
    payload: info,
});

export const setValueArea = (isOpen) => ({
    type: 'SET_VALUE_AREA',
    payload: isOpen,
});

export const setValueSubArea = (isOpen) => ({
    type: 'SET_VALUE_SUBAREA',
    payload: isOpen,
});

export const setValuePlace = (isOpen) => ({
    type: 'SET_VALUE_PLACE',
    payload: isOpen,
});

export const setOpenLoader = (isOpen) => ({
    type: 'SET_OPEN_LOADER',
    payload: isOpen,
});

export const setTitleLoader = (title) => ({
    type: 'SET_TITLE_LOADER',
    payload: title,
});
