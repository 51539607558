import React from 'react';
import { Input, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const CustomInputSearch = (props) => {
    const {
        placeholder,
        value,
        onChange,
        onClick,
        handleKeyPress
    } = props;

    return (
        <Input
            fullWidth
            color="secondary"
            style={{
                marginTop: "5px",
                fontSize: "small",
                padding: "0 10px 0 10px",
            }}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            endAdornment={
                <SearchIcon onClick={onClick} fontSize='medium' style={{ border: 'solid 1px #00009e', borderRadius: '25px', padding: '2px', marginBottom: "2px", cursor: 'pointer', }} />
            }
        />
    );
};

export default CustomInputSearch;