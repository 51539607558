import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import GeneralService from '../../../services/general';
import image1 from "../../../assets/img/icons/inspection.png";
import image2 from "../../../assets/img/icons/management.png";
import image3 from "../../../assets/img/icons/unsafeBehavior.png";
import image4 from "../../../assets/img/icons/unsafeCondition.png";
import image5 from "../../../assets/img/icons/accident.png";
import image6 from "../../../assets/img/icons/report.png";
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../message/validations';
import { Global } from '../../../config/general';
import { clearStorage } from '../../../utils/function';
import { CODES } from '../../../utils/codesHTTP';

const pathInit = Global.path;
const cards = [
    {
        id_pantalla: 1,
        desPantalla: "DTO",
        shortName: "Insp DTO",
        image: image1,
        link: `${(pathInit || '')}/inspection/DTO`,
    },
    // {
    //     id_pantalla: 2,
    //     title: "Gestión",
    //     image: image2,
    //     link: `${(pathInit || '')}/unsafe-condition-closure`,
    // },
    {
        id_pantalla: 3,
        desPantalla: "Comportamiento inseguro",
        shortName: "Comp Inse",
        image: image3,
        link: `${(pathInit || '')}/unsafe-behavior`,
    },
    {
        id_pantalla: 4,
        desPantalla: "Condición insegura	",
        shortName: "Cond Inse",
        image: image4,
        link: `${(pathInit || '')}/unsafe-condition`
    },
    {
        id_pantalla: 5,
        desPantalla: "Accidentes/Incidentes",
        shortName: 'Acc / Inc',
        image: image5,
        link: `${(pathInit || '')}/accident-incident`,
    },
    // {
    //     id_pantalla: 6,
    //     title: "Reportes",
    //     image: image6,
    //     link: "",
    // },
];

function Module({ imageSource, title, name, url, }) {
    const imageUrl = require(`../../../assets/img/icons/${imageSource}`);

    return (
        <Tooltip text={title}>
            <div className="modules-icon" onClick={() => window.location.href = url ? url : "/"}>
                <img src={imageUrl} alt={title} />
                <p>{name}</p>
            </div>
        </Tooltip>
    );
}

const Tooltip = ({ children, text }) => {
    return (
        <div className="tooltip-container">
            {children}
            <span className="tooltip-text">{text}</span>
        </div>
    );
};

const Home = ({ userInfo }) => {
    const navigate = useNavigate();
    const idUsuario = userInfo?.user?.id_usuario;
    const [menu, setMenu] = useState([]);

    useEffect(() => {
        showMenu();
    }, [idUsuario]);

    const showMenu = async () => {
        const params = {
            id_usuario: idUsuario,
        };
        try {
            setMenu([]);
            const result = await GeneralService.showMenu(params);
            if (result.status === CODES.SUCCESS_200) {
                setMenu(result?.data?.filter(e => +e.ubicacion === 1));
            } else if (result.response.status === CODES.VALIDATION_ERROR) {
                simpleAlerts({ message: result.response?.data.message || validationMessages.validation_error, type: "error" })
                setTimeout(() => {
                    navigate((pathInit || '/'));
                    window.location.reload();
                }, 3000);
            } else {
                simpleAlerts({ message: result.response?.data.error || validationMessages.expired_session, type: "error" })
                setTimeout(() => {
                    closing_session();
                }, 3000);
            }
            return []
        } catch (error) {
            console.log(`Error en setMenu: ${error}`);
        }
    }

    const closing_session = () => {
        clearStorage();
        setTimeout(() => {
            navigate(pathInit);
            window.location.reload();
        }, 1000);
    }

    return (
        <div className="modules-dashboard">
            {menu.map(({ id_pantalla, desPantalla, shortName, imagen, link, }) => {
                return (
                    <Module key={id_pantalla} imageSource={imagen} title={desPantalla} name={shortName} url={link} />
                );
            })}
        </div>
    )
}

export default Home