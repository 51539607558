import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from "./CustomSelect";
import CustomInput from "./CustomInput";

const IncidentLocationFields = ({ info, setInfo }) => {

    const areas = useSelector(state => state.areas);
    const subAreas = useSelector(state => state.subAreas);
    const places = useSelector(state => state.places);

    const valuePlant = useSelector(state => state.valuePlant);

    const [dataAreas, setDataAreas] = useState(null);
    const [dataSubAreas, setDataSubAreas] = useState(null);
    const [dataPlaces, setDataPlaces] = useState(null);

    useEffect(() => {
        setDataAreas(null);
        setDataSubAreas(null);
        setDataPlaces(null);
        setInfo({ ...info, area_id: null, subArea_id: null, lugar_id: null, });
        if (valuePlant > 0) {
            setDataAreas(areas?.filter((e) => +e.planta_id === +valuePlant));
        }
    }, [valuePlant]);

    useEffect(() => {
        setDataSubAreas(null);
        setDataPlaces(null);
        setInfo({ ...info, subArea_id: null, lugar_id: null, })
        if (info?.area_id > 0) {
            setDataSubAreas(subAreas?.filter((e) => +e.area_id === +info?.area_id));
        }
    }, [info?.area_id]);

    useEffect(() => {
        setDataPlaces(null);
        setInfo({ ...info, lugar_id: null, })
        if (info?.subArea_id > 0) {
            setDataPlaces(places?.filter((e) => +e.subArea_id === +info?.subArea_id));
        }
    }, [info?.subArea_id]);

    return (
        <div className='gap-3'>
            <div className='row justify-content-center gap-2'>
                <div className='col-md-4 col-sm-8'>
                    <CustomInput
                        title={"Fecha y Hora"}
                        type={"datetime-local"}
                        value={info?.fecha}
                        name={"fecha"}
                        onChange={(e) => setInfo({ ...info, fecha: e.target.value })}
                        required={true}
                    />
                </div>
                <div className='col-md-4 col-sm-8'>
                    <CustomSelect
                        title={'Área'}
                        placeholder={'Selecionar Área'}
                        options={dataAreas}
                        value={info?.area_id || 0}
                        onChange={(value, event) => setInfo({ ...info, area_id: value })}
                    />
                </div>
            </div>
            <div className='row justify-content-center gap-2'>
                <div className='col-md-4 col-sm-8'>
                    <CustomSelect
                        title={'Sub-área'}
                        placeholder={'Selecionar Sub-Área'}
                        options={dataSubAreas}
                        value={info?.subArea_id || 0}
                        onChange={(value, event) => setInfo({ ...info, subArea_id: value })}
                    />
                </div>
                <div className='col-md-4 col-sm-8'>
                    <CustomSelect
                        title={'Lugar'}
                        placeholder={'Selecionar Lugar'}
                        options={dataPlaces}
                        value={info?.lugar_id || 0}
                        onChange={(value, event) => setInfo({ ...info, lugar_id: value })}
                    />
                </div>
            </div>
        </div>
    )
}

export default IncidentLocationFields