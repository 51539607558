import React from 'react'
import CustomInput from '../../General/CustomInput';
import CustomInputInfo from '../../General/CustomInputInfo';
import BodyPlants from './BodyPlants';

const InformationAddPlant = (props) => {
    const { information, data, dataExists, dataSelected, setDataSelected, activeTab, setActiveTab, } = props;

    const handleChangeTab = (index) => {
        setDataSelected(null);
        setActiveTab(index);
    };

    return (
        <div>
            <div className='row'>
                <div className='col-md-6 col-sm-12'>
                    <CustomInputInfo
                        title={"Usuario"}
                        value={information?.usuario?.toUpperCase()}
                    />
                </div>
                <div className='col-md-6 col-sm-12'>
                    <CustomInputInfo
                        title={"Nombre"}
                        value={information?.trabajador}
                    />
                </div>
            </div>

            <ul className="nav nav-underline small mt-2">
                <li className="nav-item" onClick={() => handleChangeTab(0)}>
                    <a className={`nav-link item-small ${activeTab === 0 ? 'active' : ''}`}>Listar</a>
                </li>
                <li className="nav-item" onClick={() => handleChangeTab(1)}>
                    <a className={`nav-link item-small ${activeTab === 1 ? 'active' : ''}`}>Agregar</a>
                </li>
            </ul>

            <BodyPlants
                data={activeTab > 0 ? data : dataExists}
                dataExists={dataExists}
                dataSelected={dataSelected}
                setDataSelected={setDataSelected}
                activeTab={activeTab}
                id={"id_planta"}
            />
        </div>
    )
}

export default InformationAddPlant