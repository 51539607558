import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;
const prefix = 'worker';
const url = window.location.pathname;
const commonParams = { url: url };

class WorkerService {
    async showByPlant(queryParameters) {
        const params = { ...commonParams, pageSize: 20, pagination: 1, ...queryParameters };
        const ruta = `${API_URL}/${prefix}/plant`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }
    
    async showOthers(queryParameters) {
        const params = { ...commonParams, ...queryParameters };
        const ruta = `${API_URL}/${prefix}/others`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            params: params,
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }
}

export default new WorkerService();