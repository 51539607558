import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;
const prefix = "file";
const url = window.location.pathname;
const commonParams = { url: url };

class FileService {
    async viewFileS3(filePath) {
        const ruta = `${API_URL}/${prefix}/view?archivo=${filePath}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            responseType: 'blob',
            params: params,
        });
    }

    async downloadFileS3(archivo) {
        const ruta = `${API_URL}/${prefix}/download?archivo=${archivo}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            responseType: 'blob',
            params: params,
        });
    }
}

export default new FileService();